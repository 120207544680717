import { useDispatch, useSelector } from 'react-redux'
import { DetailsModalContent } from './content'
import { packagesSelectors } from 'app/containers/Package/selectors'
import { PackagesActions } from 'app/containers/Package/slice'
import { Modal } from 'app/components/modal'

export const DetailsModal = () => {
  const dispatch = useDispatch()

  const detailsModalSelectedItem = useSelector(
    packagesSelectors.detailsModalSelectedItem,
  )
  const handleModalClose = () => {
    dispatch(PackagesActions.setDetailModalSelectedItem(undefined))
  }

  return (
    <Modal open={!!detailsModalSelectedItem} onClose={handleModalClose}>
      {detailsModalSelectedItem && <DetailsModalContent />}
    </Modal>
  )
}
