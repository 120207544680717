import { ApiKeys } from './containers/ApiKey'
import { Applications } from './containers/Applications'
import { OAuth } from './containers/OAuth'
import { Packages } from './containers/Package'
import { Profile } from './containers/Profile'
import { Roles } from './containers/Roles'
import { WebhookList } from './containers/Webhook'
import { IRoute } from './types'

export const routes: Array<IRoute> = [
  {
    key: '/profile',
    title: 'Profile',
    path: '/profile',
    enabled: true,
    component: Profile,
  },
  {
    key: 'Packages',
    title: 'Packages',
    path: '/Packages',
    enabled: true,
    component: Packages,
  },
  {
    key: 'applications',
    title: 'applications',
    path: '/applications',
    enabled: true,
    component: Applications,
  },
  {
    key: 'ApiKeys',
    title: 'ApiKeys',
    path: '/ApiKeys',
    enabled: true,
    component: ApiKeys,
  },

  {
    key: 'OAuth',
    title: 'OAuth',
    path: '/OAuth',
    enabled: true,
    component: OAuth,
  },
  {
    key: 'Roles',
    title: 'Roles',
    path: '/roles',
    enabled: true,
    component: Roles,
  },
  {
    key: 'Webhook',
    title: 'Webhook',
    path: '/webhook',
    enabled: true,
    component: WebhookList,
  },
  {
    key: 'checkout',
    title: 'checkout',
    path: '/checkout/*',
    enabled: true,
    component: Packages,
  },
]
