import { useDispatch } from 'react-redux'
import { PageHeader } from '../../components/common/globals/pageHeader'
import { ApiKeysTable } from './components/Table'
import AddApiKeyModal from './components/modals/addApi'
import { ApiKeysActions } from './slice'
import PrimaryButton from 'app/components/button/primaryButton'

interface Props {}

export function ApiKeys(props: Props) {
  const dispatch = useDispatch()

  const handleAddApiClick = () => dispatch(ApiKeysActions.setDisplayModal(true))

  return (
    <>
      <PageHeader title="API Keys">
        <PrimaryButton onClick={handleAddApiClick}>Add Api Key</PrimaryButton>
      </PageHeader>
      <ApiKeysTable />
      <AddApiKeyModal />
    </>
  )
}
