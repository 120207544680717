import { AppPages } from 'app/constants'
import { profileSelectors } from 'app/containers/Profile/selectors'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { customToast } from 'app/components/toast/CustomToast'
import { ThreeDotLoadingIndicator } from 'app/components/common/loading/ThreeDotLoadingIndicator/threeDotLoading'
interface Props {
  children: React.ReactNode
}
export const PrivateRoute: FC<Props> = ({ children }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // add loading state, initially true for initial render
  const [isLoading, setIsLoading] = useState(true)

  const kycData = useSelector(profileSelectors.kyc)
  const kycError = useSelector(profileSelectors.error)
  const isUserUnregistered = useSelector(profileSelectors.isUserUnregistered)

  const checkAuth = async () => {
    setIsLoading(true) // <-- set true when starting auth check
    try {
      const token = localStorage.getItem('AUTH_ACCESS_TOKEN')

      if (token) {
        setIsAuthenticated(true)
      }
    } catch (e) {
      console.error(e)
      setIsAuthenticated(false)
    } finally {
      setIsLoading(false) // <-- clear loading state when completed
    }
  }

  // FIXME add check orgid on navigate
  // const checkOrgId = async () => {
  //   setIsLoading(true);
  //   try {
  //     const token = localStorage.getItem("AUTH_ACCESS_TOKEN");

  //     if (token) {
  //       setIsAuthenticated(true);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //     setIsAuthenticated(false);
  //   } finally {
  //     setIsLoading(false); // <-- clear loading state when completed
  //   }
  // };

  useEffect(() => {
    checkAuth()
    if (isUserUnregistered && pathname !== '/profile') {
      navigate(AppPages.profile)
      customToast.error('Please Kyc Your Data First')
    }
  }, [pathname, isUserUnregistered, navigate]) // <-- check auth status on mount/when location changes

  if (isLoading) {
    return <ThreeDotLoadingIndicator fullPageHeight />
  }

  let output

  if (kycData || kycError) {
    output = children || <Outlet />
  } else {
    output = <ThreeDotLoadingIndicator fullPageHeight />
  }
  return isAuthenticated ? output : <Navigate to={'/login'} />
}
