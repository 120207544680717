import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const webhookDomains = {
  root: (state: RootState) => state.webhook || initialState,
  error: (state: RootState) => state.webhook.error,
  webhookLoading: (state: RootState) => state.webhook.webhookLoading,
  isWebhookModalDisplayed: (state: RootState) =>
    state.webhook.isWebhookModalDisplayed,
  webhook: (state: RootState) => state.webhook.webhook,
  selectedClientDomainUUID: (state: RootState) =>
    state.webhook.selectedClientDomainUUID,
  lastWebhookId: (state: RootState) => state.webhook.lastWebhookId,
}
export const webhookSelectors = {
  root: createSelector(webhookDomains.root, root => root),
  error: createSelector(webhookDomains.error, error => error),
  webhookLoading: createSelector(
    webhookDomains.webhookLoading,
    webhookLoading => webhookLoading,
  ),
  isWebhookModalDisplayed: createSelector(
    webhookDomains.isWebhookModalDisplayed,
    isWebhookModalDisplayed => isWebhookModalDisplayed,
  ),
  selectedClientDomainUUID: createSelector(
    webhookDomains.selectedClientDomainUUID,
    selectedClientDomainUUID => selectedClientDomainUUID,
  ),
  lastWebhookId: createSelector(
    webhookDomains.lastWebhookId,
    lastWebhookId => lastWebhookId,
  ),

  webhook: createSelector(webhookDomains.webhook, webhook => webhook),
}
