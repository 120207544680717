import { PageHeader } from 'app/components/common/globals/pageHeader'
import { WebhooksTable } from './components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { applicationsSelectors } from '../Applications/selectors'
import { webhookSelectors } from './selector'
import { useEffect, useMemo } from 'react'
import { ApplicationsActions } from '../Applications/slice'
import { SelectApplication } from './components/SelectDomainDropDown'
import { webhookActions } from './slice'
import { PageLoading } from 'app/components/common/loading/pageLoading'

export function WebhookList() {
  const dispatch = useDispatch()
  const domains = useSelector(applicationsSelectors.domains)
  const selectedClientDomainUUID = useSelector(
    webhookSelectors.selectedClientDomainUUID,
  )

  useEffect(() => {
    dispatch(ApplicationsActions.getApplications())
  }, [dispatch])

  const selectedDomain = useMemo(() => {
    return domains?.find(domain => domain.uuid === selectedClientDomainUUID)
      ?.domain
  }, [domains, selectedClientDomainUUID])

  useEffect(() => {
    dispatch(webhookActions.setSelectedClientDomainUUID(domains[0]?.uuid))
  }, [dispatch, domains])

  useEffect(() => {
    selectedClientDomainUUID && dispatch(webhookActions.getWebhook())
  }, [selectedClientDomainUUID, dispatch])

  return (
    <>
      {!selectedClientDomainUUID ? (
        <PageLoading />
      ) : (
        <>
          <PageHeader
            title={'Webhook' + (selectedDomain ? `: ${selectedDomain}` : null)}
          >
            <SelectApplication />
          </PageHeader>
          <WebhooksTable />
        </>
      )}
    </>
  )
}
