import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { OAUTH_FORM_VALUES } from '../types'
import { OAuthsEndpoints } from 'api/endpoints/oAuths'

export function* getOAuthsList() {
  const response: AxiosResponse = yield axiosInstance.get(
    OAuthsEndpoints.oAuthsList,
  )
  return response
}

export function* createOAuthReq(payload: OAUTH_FORM_VALUES) {
  const response: AxiosResponse = yield axiosInstance.post(
    OAuthsEndpoints.createOAuth,
    payload,
  )
  return response
}

export function* updateOAuthReq(payload: OAUTH_FORM_VALUES) {
  const response: AxiosResponse = yield axiosInstance.post(
    OAuthsEndpoints.updateOAuth,
    payload,
  )
  return response
}
