import { OAUTH_FORM_VALUES } from './types'

export const OAUTH_VALUES_INITIAL_STATE: OAUTH_FORM_VALUES = {
  audiences: [],
  redirectUris: [],
  clientDomainUUID: '',
}

export const oAuthColumns = [
  { id: 'name', label: 'Domain Name' },
  { id: 'oauth2ClientID', label: 'OAuths' },
  { id: 'action', label: 'Edit', actionKey: 'uuid' },
]
