import { createSlice } from 'store/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { RolesFormData, RolesState } from './types'

export const initialState: RolesState = {
  roles: [],
  isRolesModalDisplayed: false,
  isLoading: false,
  error: null,
  allClients: [],
}

const RolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setDisplayModal(state, action: PayloadAction<boolean>) {
      state.isRolesModalDisplayed = action.payload
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setRolesError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    getRoles() {},
    setRoles: (state, action: PayloadAction<string[]>) => {
      state.roles = action.payload
      state.error = null
    },
    addClientRole(state, action: PayloadAction<RolesFormData>) {},
    updateClient(state, action: PayloadAction<RolesFormData>) {},
    getAllClients() {},
    setAllClients: (state, action: PayloadAction<RolesState['allClients']>) => {
      state.allClients = action.payload
      state.error = null
    },
    setModalAndSelectedClient(
      state,
      action: PayloadAction<{
        displayModal: boolean
        selectedClient: RolesState['selectedClient']
      }>,
    ) {
      state.isRolesModalDisplayed = action.payload.displayModal
      state.selectedClient = action.payload.selectedClient
    },
  },
})
export const {
  actions: RolesActions,
  reducer: RolesReducer,
  name: sliceKey,
} = RolesSlice
