import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { ApplicationsActions } from '../../slice'
import { useDispatch, useSelector } from 'react-redux'
import { applicationsSelectors } from '../../selectors'
import { styled } from '@mui/material'
import {
  ADD_APP_STEPS,
  APP_STEPS_NAMES,
  APP_TYPES_OPTIONS,
} from '../../constants'
import PrimaryButton from 'app/components/button/primaryButton'
import {
  COLUMN_ALIGN_START_JUSTIFY_CENTER,
  ROW_ALIGN_END_SPACE_B,
} from 'styles/globalStyles'

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0)
  const dispatch = useDispatch()
  const { name, domain, framework, bundleId, platform } = useSelector(
    applicationsSelectors.newApppValues,
  )

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    if (activeStep === 1) {
      dispatch(
        ApplicationsActions.setNewAppValues({
          name: '',
          domain: '',
          bundleId: '',
          framework: '',
        }),
      )
    }
  }

  const newAppValues = useSelector(applicationsSelectors.newApppValues)

  const handleFinishClick = () => {
    const newCustomAppValues = {
      ...newAppValues,
      bundleId: undefined,
      domain: newAppValues.domain ? domain : newAppValues.bundleId,
    }
    dispatch(ApplicationsActions.createNewApplication(newCustomAppValues))
  }

  const isNextButtonDisabled = () => {
    switch (ADD_APP_STEPS[activeStep].label) {
      case APP_STEPS_NAMES.SELECT_PLATFORM:
        return !platform
      case APP_STEPS_NAMES.ENTER_APPLICATION_DETAILS:
        const commonValidation = name === '' || framework === ''
        return platform === APP_TYPES_OPTIONS.WEB
          ? commonValidation || domain === ''
          : commonValidation || bundleId === ''
      case APP_STEPS_NAMES.SUMMARY_OF_INFORMATION:
        return false
      default:
        return false
    }
  }

  return (
    <>
      <Stepper activeStep={activeStep}>
        {ADD_APP_STEPS.map(({ label }) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <StepWrapper>
        {React.createElement(ADD_APP_STEPS[activeStep].component)}
      </StepWrapper>
      {
        <React.Fragment>
          <StepNavWrapper>
            <StyledNavButton disabled={activeStep === 0} onClick={handleBack}>
              Back
            </StyledNavButton>
            {activeStep === ADD_APP_STEPS.length - 1 ? (
              <StyledNavButton onClick={handleFinishClick}>
                Finish
              </StyledNavButton>
            ) : (
              <StyledNavButton
                disabled={isNextButtonDisabled()}
                onClick={handleNext}
              >
                Next
              </StyledNavButton>
            )}
          </StepNavWrapper>
        </React.Fragment>
      }
    </>
  )
}

const StepNavWrapper = styled(Box)`
  ${ROW_ALIGN_END_SPACE_B}
  padding-top: 16px;
`

const StyledNavButton = styled(PrimaryButton)`
  margin-right: 8px;
`

export const StepWrapper = styled('div')`
  height: 200px;
  width: 100%;
  gap: 20px;
  ${COLUMN_ALIGN_START_JUSTIFY_CENTER};
`
