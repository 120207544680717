import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const profileDomains = {
  root: (state: RootState) => state.profile || initialState,
  kycLoading: (state: RootState) => state.profile.kycLoading,
  kyc: (state: RootState) => state.profile.kyc,
  error: (state: RootState) => state.profile.error,
  kycQrCode: (state: RootState) => state.profile.kycQrCode,
  displayModal: (state: RootState) => state.profile.displayModal,
  organizations: (state: RootState) => state.profile.organizations,
  selectedOrganization: (state: RootState) =>
    state.profile.selectedOrganization,
}

export const profileSelectors = {
  root: createSelector(profileDomains.root, root => root),
  kycLoading: createSelector(
    profileDomains.kycLoading,
    kycLoading => kycLoading,
  ),
  kyc: createSelector(profileDomains.kyc, kyc => kyc),
  error: createSelector(profileDomains.error, error => error),
  clientUuid: createSelector(profileDomains.kyc, kyc => kyc?.uuid),
  kycQrCode: createSelector(profileDomains.kycQrCode, kycQrCode => kycQrCode),
  displayModal: createSelector(
    profileDomains.displayModal,
    displayModal => displayModal,
  ),
  isUserUnregistered: createSelector(
    profileDomains.error,
    error => error?.code === 404 || error?.status === 404,
  ),
  organizations: createSelector(
    profileDomains.organizations,
    organizations => organizations,
  ),
  selectedOrganization: createSelector(
    profileDomains.selectedOrganization,
    selectedOrganization => selectedOrganization,
  ),
}
