import authorizer from './Authorizer'
export const loginSideEffect = () => {
  localStorage.clear()
  authorizer.makeAuthorizationRequest()
}

export const logInCallbackSideEffect = async () => {
  const authToken = await authorizer.signinCallback()
  return authToken
}

export const refreshTokenCallbackSideEffect = async () => {
  const authToken = await authorizer.refreshTokenCallback()
  return authToken
}
