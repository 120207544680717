/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from 'store/toolkit'
import { OAUTH_FORM_VALUES, OAUTH_VALUES, OAuthState } from './types'

// The initial state of the Applications container
export const initialState: OAuthState = {
  oauth: [],
  displayModal: false,
  displayDeleteModal: false,
  isLoadingOauth: false,
  clientDomainUUID: '',
}

const apiKeysSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    getOAuths(state) {},
    setOAuths(state, action: PayloadAction<OAUTH_VALUES[]>) {
      state.oauth = action.payload
    },
    setDisplayModal(state, action: PayloadAction<boolean>) {
      state.displayModal = action.payload
      if (!action.payload) {
        state.crudSelectedItem = undefined
      }
    },
    setDeisplayDeleteModal(state, action: PayloadAction<boolean>) {
      state.displayModal = action.payload
      if (!action.payload) {
        state.crudSelectedItem = undefined
      }
    },
    createUpdateOAuth(state, action: PayloadAction<OAUTH_FORM_VALUES>) {},
    deleteOAuth(state) {},
    setCrudSelectedItem(
      state,
      action: PayloadAction<OAuthState['crudSelectedItem']>,
    ) {
      state.crudSelectedItem = action.payload
      state.displayModal = true
    },
    setDeleteSelectedItem(
      state,
      action: PayloadAction<OAuthState['crudSelectedItem'] | undefined>,
    ) {
      state.crudSelectedItem = action.payload
      state.displayDeleteModal = true
    },
    setIsLoadingOauth(state, action: PayloadAction<boolean>) {
      state.isLoadingOauth = action.payload
    },
    setClientDomainUuid(state, action: PayloadAction<string>) {
      state.clientDomainUUID = action.payload
    },
  },
})

export const {
  actions: OAuthActions,
  reducer: OAuthReducer,
  name: sliceKey,
} = apiKeysSlice
