import { useDispatch, useSelector } from 'react-redux'
import { OAuthForm } from '../oauthForm'
import { OAuthActions } from '../../slice'
import { oauthSelectors } from '../../selectors'
import { Modal } from 'app/components/modal'

export default function AddOAuthModal() {
  const dispatch = useDispatch()
  const displayModal = useSelector(oauthSelectors.displayModal)
  const handleModalClose = () => dispatch(OAuthActions.setDisplayModal(false))

  return (
    <Modal open={displayModal} onClose={handleModalClose}>
      <OAuthForm />
    </Modal>
  )
}
