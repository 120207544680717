import BaseInput from 'app/components/input/baseInput/index'
import BaseDropdownInput from 'app/components/input/dropdownInput'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { ApplicationsActions } from 'app/containers/Applications/slice'
import { NewApppValues } from 'app/containers/Applications/types'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormHelperText, SelectChangeEvent, styled } from '@mui/material'
import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
} from 'styles/globalStyles'
import { validationSchema } from '../validationSchema'
import { useFormik } from 'formik'
import {
  APP_TYPES_OPTIONS,
  MOBILE_FRAMEWORK_OPTIONS,
  WEB_FRAMEWORK_OPTIONS,
} from 'app/containers/Applications/constants'

interface Props {}

export const AppDetail: FC<Props> = props => {
  const dispatch = useDispatch()
  const { name, domain, framework, platform, bundleId } = useSelector(
    applicationsSelectors.newApppValues,
  )

  const { values, errors, setFieldValue, touched, handleBlur, setErrors } =
    useFormik<NewApppValues>({
      initialValues: {
        name: name || '',
        domain: domain || '',
        bundleId: bundleId || '',
        framework: framework || '',
      },
      validationSchema,
      onSubmit: () => {},
      validateOnChange: false,
    })

  useEffect(() => {
    if (platform === APP_TYPES_OPTIONS.MOBILE) {
      delete errors.domain
      setErrors({ ...errors })
    } else {
      delete errors.bundleId
      setErrors({ ...errors })
    }

    if (!Object.keys(errors).length) {
      dispatch(ApplicationsActions.setNewAppValues(values))
    } else if (errors.bundleId) {
      dispatch(ApplicationsActions.setNewAppValues({ ...values, bundleId: '' }))
    }
  }, [
    dispatch,
    errors,
    values,
    errors.domain,
    platform,
    errors.bundleId,
    setErrors,
  ])

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    setFieldValue(name, value, true)
  }

  const handleFrameworkChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target
    setFieldValue(name, value, true)
  }

  return (
    <DeatilsContainer>
      <InputWrapper>
        <InputLabel>Name:</InputLabel>
        <BaseInputWrapper
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && !!errors.name}
          helperText={touched.name ? errors.name : ''}
        />
      </InputWrapper>
      {platform === APP_TYPES_OPTIONS.WEB ? (
        <InputWrapper>
          <InputLabel>Domain:</InputLabel>
          <BaseInputWrapper
            name="domain"
            value={values.domain}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.domain && !!errors.domain}
            helperText={touched.domain ? errors.domain : ''}
          />
        </InputWrapper>
      ) : (
        <InputWrapper>
          <InputLabel>Bundle ID:</InputLabel>
          <BaseInputWrapper
            name="bundleId"
            value={values.bundleId}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.bundleId && !!errors.bundleId}
            helperText={touched.bundleId ? errors.bundleId : ''}
          />
        </InputWrapper>
      )}
      <InputWrapper>
        <InputLabel>Framework:</InputLabel>
        <DropDownWrapper>
          <BaseDropdownInputWrapper
            name="framework"
            value={values.framework}
            onChange={handleFrameworkChange}
            onBlur={handleBlur}
            options={
              platform === APP_TYPES_OPTIONS.WEB
                ? WEB_FRAMEWORK_OPTIONS
                : MOBILE_FRAMEWORK_OPTIONS
            }
            error={touched.framework && !!errors.framework}
          />
          <ErrorFormHelperText error>
            {touched.framework ? errors.framework : ''}
          </ErrorFormHelperText>
        </DropDownWrapper>
      </InputWrapper>
    </DeatilsContainer>
  )
}

const DeatilsContainer = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
  gap: 15px;
  margin-top: 5px;
  position: relative;
`
const InputWrapper = styled('div')`
  ${ROW_CENTER}
`
const BaseInputWrapper = styled(BaseInput)`
  width: 300px;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    left: 0;
    height: 20px;
    font-size: 12px;
  }
`

const BaseDropdownInputWrapper = styled(BaseDropdownInput)`
  width: 300px;
`

const InputLabel = styled('p')`
  min-width: 100px;
  font-weight: bold;
`

const DropDownWrapper = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
`
const ErrorFormHelperText = styled(FormHelperText)`
  position: absolute;
  bottom: -20px;
  left: 111px;
  height: 20px;
  font-size: 12px;
`
