import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'

export interface CRadioGroupProps extends RadioGroupProps {
  options: { label: string; value: number | string }[]
  value?: string | number
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
}

export const CRadioGroup: FC<CRadioGroupProps> = ({
  options,
  value,
  name,
  handleChange,
}) => {
  return (
    <StyledRadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      value={value}
      onChange={handleChange}
      name={name}
    >
      {options?.map(({ label, value }) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<Radio />}
          label={label}
        />
      ))}
    </StyledRadioGroup>
  )
}

const StyledRadioGroup = styled(RadioGroup)`
  flex-flow: wrap !important;
`
