import { ApplicationsActions } from 'app/containers/Applications/slice'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
} from 'styles/globalStyles'
import { useFormik } from 'formik'
import { OAUTH_VALUES_INITIAL_STATE } from '../constants'
import { validationSchema } from './validationSchema'
import { OAUTH_FORM_VALUES } from '../types'
import CInputMultiple from 'app/components/input/multiInput'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { oauthSelectors } from '../selectors'
import PrimaryButton from 'app/components/button/primaryButton'
import { CRadioGroup } from 'app/components/radio-group'
import { OAuthActions } from '../slice'

interface Props {}

export const OAuthForm: FC<Props> = props => {
  const dispatch = useDispatch()
  const crudSelectedItem = useSelector(oauthSelectors.crudSelectedItem)

  useEffect(() => {
    dispatch(ApplicationsActions.getApplications())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = () => {
    dispatch(OAuthActions.createUpdateOAuth(values))
  }
  const domains = useSelector(applicationsSelectors.domains)

  const initialValues: OAUTH_FORM_VALUES = crudSelectedItem
    ? {
        uuid: crudSelectedItem?.uuid,
        audiences: crudSelectedItem?.audiences,
        redirectUris: crudSelectedItem?.redirectUris,
      }
    : OAUTH_VALUES_INITIAL_STATE
  const { values, errors, handleSubmit, setFieldValue } =
    useFormik<OAUTH_FORM_VALUES>({
      initialValues: initialValues,
      validationSchema,
      onSubmit,
      validateOnChange: false,
    })

  return (
    <StyledForm onSubmit={handleSubmit}>
      <DeatilsContainer>
        <RowContainer>
          <div>Audiences:</div>
          <CInputMultiple
            value={values.audiences}
            onChange={(name, data) => setFieldValue(name, data)}
            name="audiences"
            label="Audiences"
            errors={errors}
          />
        </RowContainer>
        <RowContainer>
          <div>Redirect Uris:</div>
          <CInputMultiple
            value={values.redirectUris}
            onChange={(name, data) => setFieldValue(name, data)}
            name="redirectUris"
            label="Redirect Uris"
            errors={errors}
          />
        </RowContainer>
        {!crudSelectedItem ? (
          <RowContainer>
            <div>Select a domain:</div>
            <CRadioGroup
              name="clientDomainUUID"
              options={domains?.map(({ uuid, domain }) => ({
                label: domain,
                value: uuid,
              }))}
              value={values.clientDomainUUID}
              handleChange={event =>
                setFieldValue('clientDomainUUID', event.target.value)
              }
            />
          </RowContainer>
        ) : undefined}
        <PrimaryButton fullWidth type="submit">
          Submit
        </PrimaryButton>
      </DeatilsContainer>
    </StyledForm>
  )
}

const DeatilsContainer = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
  gap: 30px;
`

const RowContainer = styled('div')`
  ${ROW_CENTER}
  gap: 20px;
  & > *:first-of-type {
    font-weight: 700;
  }
`

export const StyledForm = styled('form')`
  width: 100%;
`
