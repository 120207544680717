import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { ApplicationsActions } from '../slice'
import { ApplicationsEndpoints } from 'api/endpoints/applications'

export function* getApplicationsList() {
  const response: AxiosResponse = yield axiosInstance.get(
    ApplicationsEndpoints.applicationsList,
  )
  return response
}

export function* createNewApplicationRequest(
  action: ReturnType<typeof ApplicationsActions.createNewApplication>,
) {
  const response: AxiosResponse = yield axiosInstance.post(
    ApplicationsEndpoints.createNewApplication,
    action.payload,
  )
  return response
}

export function* checkDomainStatusReq(uuid: string) {
  const response: AxiosResponse = yield axiosInstance.post(
    ApplicationsEndpoints.checkDomainStatus,
    { uuid },
  )
  return response
}
