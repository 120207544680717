import { PageHeader } from '../../components/common/globals/pageHeader'
import { ApplicationsTable } from './components/Table'
import { ApplicationsActions } from './slice'
import { useDispatch } from 'react-redux'
import PrimaryButton from 'app/components/button/primaryButton'
import AddApplicationModal from './components/modals/addApp'

interface Props {}

export function Applications(props: Props) {
  const dispatch = useDispatch()

  const handleModalOpen = () =>
    dispatch(ApplicationsActions.setDisplayModal(true))

  return (
    <>
      <PageHeader title="Applications">
        <PrimaryButton onClick={handleModalOpen}>Add Application</PrimaryButton>
      </PageHeader>
      <ApplicationsTable />
      <AddApplicationModal />
    </>
  )
}
