import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { PackagesActions } from '../slice'
import AutorenewIcon from '@mui/icons-material/Autorenew'

interface ActionProps {
  rowId: string
  actionHasBeenDone?: boolean
}
export const RenewActionComponent = ({
  rowId,
  actionHasBeenDone,
}: ActionProps) => {
  const dispatch = useDispatch()
  const handleRenewClick = (uuid: string) => {
    dispatch(PackagesActions.startPackageRenewal(uuid))
  }

  return (
    <IconButton onClick={() => handleRenewClick(rowId)}>
      <AutorenewIcon />
    </IconButton>
  )
}
