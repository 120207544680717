import { FC } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from '@mui/material'
import { ThreeDotLoadingIndicator } from 'app/components/common/loading/ThreeDotLoadingIndicator/threeDotLoading'

interface Props extends SelectProps {
  options: string[] | number[] | { label: string; value: number | string }[]
  isLoading?: boolean
}

const BaseDropdownInput: FC<Props> = ({ isLoading, ...props }) => {
  return (
    <>
      <FormControl size="small" fullWidth={props.fullWidth}>
        <BLabel>{props.label}</BLabel>
        <BSelect
          value={props.value ?? ''}
          name={props.name}
          onChange={props.onChange}
          label={props.label}
          {...props}
        >
          {isLoading ? (
            <MenuItem disabled>
              <ThreeDotLoadingIndicator />
            </MenuItem>
          ) : (
            props.options.map((option, index) => (
              <MenuItem
                key={index}
                value={typeof option === 'object' ? option.value : option}
              >
                {typeof option === 'object' ? option.label : option}
              </MenuItem>
            ))
          )}
        </BSelect>
      </FormControl>
    </>
  )
}

export const BSelect = styled(Select)(() => ({
  height: '35px',
  '& .MuiInputBase-root': {
    fontSize: '12px',
    paddingRight: '30px !important',
  },
  '& .MuiFormControl-root ': { height: 'auto' },
  '& .MuiTextField-root ': { height: 'auto' },
  '& .MuiChip-label': { fontSize: '12px' },
  backgroundColor: '#F5F7FB',
  '& input': {
    height: '2px',
    fontSize: '12px !important',
  },
  '& .MuiOutlinedInput-input': {},
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: `1px solid #017E84 !important`,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
    {
      border: `1px solid #017E84 !important`,
    },
  '& legend': {
    fontSize: '11px',
  },
}))

export const BLabel = styled(InputLabel)(() => ({
  fontSize: '12px  !important',
  '&.Mui-focused': {
    fontSize: '14px !important',
    color: '#000000',
  },
  '&.MuiInputLabel-shrink': {
    fontSize: '14px  !important',
    top: '0px',
  },
}))
export default BaseDropdownInput
