import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { RolesActions } from '../slice'
import EditIcon from '@mui/icons-material/Edit'
interface ActionProps {
  rowId: string
  actionHasBeenDone?: boolean
}
export const RolesActionComponent = ({
  rowId,
  actionHasBeenDone,
}: ActionProps) => {
  const dispatch = useDispatch()
  const handleSelectedClientClick = (corepassID: any) => {
    dispatch(
      RolesActions.setModalAndSelectedClient({
        displayModal: true,
        selectedClient: corepassID,
      }),
    )
  }

  // the actionHasBeenDone is used to hide the edit icon when the user is "OWNER"
  // see speceficRowStyleBasedOnCellValue in ./Grid.tsx
  return (
    <>
      {actionHasBeenDone ? (
        <></>
      ) : (
        <IconButton onClick={() => handleSelectedClientClick(rowId)}>
          <EditIcon />
        </IconButton>
      )}
    </>
  )
}
