import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { PackagesActions } from '../slice'
import DetailsIcon from '@mui/icons-material/Details'

interface ActionProps {
  rowId: string
  actionHasBeenDone?: boolean
}
export const DetailsActionComponent = ({
  rowId,
  actionHasBeenDone,
}: ActionProps) => {
  const dispatch = useDispatch()
  const handleDetailsClick = (uuid: string) => {
    dispatch(PackagesActions.setDetailModalSelectedItem(uuid))
  }

  return (
    <IconButton onClick={() => handleDetailsClick(rowId)}>
      <DetailsIcon />
    </IconButton>
  )
}
