import { useEffect } from 'react'
import { rolesColumnsWithModify, rolesColumnsWithoutModify } from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import { RolesActions } from '../slice'
import { rolesSelectors } from '../selectors'
import SortableTable from 'app/components/table/sortableTable'
import { RolesActionComponent } from './rolesActionComponent'
import { profileSelectors } from 'app/containers/Profile/selectors'
import { ROLES_VALUES } from 'app/constants'

export const RolesTable = () => {
  const dispatch = useDispatch()

  const clients = useSelector(rolesSelectors.allClients)
  const isLoading = useSelector(rolesSelectors.isLoading)
  const roleOrganizations = useSelector(profileSelectors.organizations)

  const isCurrentUserOwner = roleOrganizations[0]?.role === ROLES_VALUES.OWNER

  useEffect(() => {
    dispatch(RolesActions.getAllClients())
  }, [dispatch])

  const speceficRowStyleBasedOnCellValue = {
    actionComponent: [RolesActionComponent],
    // here we use this to rule out each row that has a role of 'OWNER'
    // in action component we have a condition that if the role is 'OWNER' then we don't show the edit icon
    cellKey: 'role',
    cellValue: ROLES_VALUES.OWNER,
  }

  return (
    <>
      <SortableTable
        name="domain-table"
        data={clients}
        columns={
          isCurrentUserOwner
            ? rolesColumnsWithModify
            : rolesColumnsWithoutModify
        }
        speceficRowStyleBasedOnCellValue={speceficRowStyleBasedOnCellValue}
        isLoading={isLoading && !(clients?.length > 0)}
        showNumberOfPages={false}
      />
    </>
  )
}
