import { Fragment, ReactNode, createElement, useState } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import PrimaryButton from 'app/components/button/primaryButton'
import { useDispatch, useSelector } from 'react-redux'
import { packagesSelectors } from '../../selectors'
import {
  ADD_PACKAGE_STEPS,
  PACKAGES_TYPES,
  PACKAGE_STEPS_NAMES,
} from '../../constants'
import styled from 'styled-components'
import {
  COLUMN_ALIGN_START_JUSTIFY_CENTER,
  ROW_ALIGN_END_SPACE_B,
} from 'styles/globalStyles'
import { isNonEmptyTruthy } from 'utils/helpers'
import { PackagesActions } from '../../slice'

export default function CreatePackageStepper() {
  const [activeStep, setActiveStep] = useState(0)
  const dispatch = useDispatch()

  const {
    clientDomainUuid,
    paymentLink,
    renewal,
    quantity,
    plan,
    requiredFields,
    optionalFields,
  } = useSelector(packagesSelectors.newSourceKey)

  const newSourceKeySelectedFields = useSelector(
    packagesSelectors.newSourceKeySelectedFields,
  )

  const isLoadingGettingPaymentLink = useSelector(
    packagesSelectors.isLoadingGettingPaymentLink,
  )

  const handleNext = () => {
    //if any of the optional fields is checked and the required field is checked, uncheck the optional field
    const requiredFieldsValues = Object.values(requiredFields)
    const optionalFieldsValues = Object.values(optionalFields)
    const optionalFieldsKeys = Object.keys(optionalFields)
    const requiredFieldsKeys = Object.keys(requiredFields)

    for (let i = 0; i < optionalFieldsKeys.length; i++) {
      if (optionalFieldsValues[i].checked) {
        for (let j = 0; j < requiredFieldsKeys.length; j++) {
          if (requiredFieldsValues[j].checked) {
            if (optionalFieldsKeys[i] === requiredFieldsKeys[j]) {
              dispatch(
                PackagesActions.handleFieldToggle({
                  label: optionalFieldsKeys[i],
                  required: false,
                }),
              )
            }
          }
        }
      }
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const isGoToPaymentButtonDisabled = !clientDomainUuid || !paymentLink

  const handleFinishClick = () => {
    if (isGoToPaymentButtonDisabled) return
    window.location.href = paymentLink
  }

  const isNextButtonDisabled = () => {
    switch (stepsBasedOnSelection[activeStep].label) {
      case PACKAGE_STEPS_NAMES.SELECT_DOMAIN:
        return !clientDomainUuid
      case PACKAGE_STEPS_NAMES.PACKAGE_TYPE:
        return !plan
      case PACKAGE_STEPS_NAMES.REQUIRED_FIELDS:
        return newSourceKeySelectedFields.length === 0
      case PACKAGE_STEPS_NAMES.QUANTITY:
        return !quantity
      default:
        return false
    }
  }

  const stepsBasedOnSelection = ADD_PACKAGE_STEPS.filter(({ included }) =>
    renewal
      ? included.includes(PACKAGES_TYPES.RENEWAL)
      : plan === '' || included.includes(plan),
  )

  return isNonEmptyTruthy(stepsBasedOnSelection) ? (
    <>
      <Stepper activeStep={activeStep}>
        {stepsBasedOnSelection.map(({ label }) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: ReactNode
          } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <StepWrapper>
        {createElement(stepsBasedOnSelection[activeStep].component, {
          ...stepsBasedOnSelection[activeStep]?.props,
        })}
      </StepWrapper>
      {
        <Fragment>
          <StepNavWrapper>
            <PrimaryButton disabled={activeStep === 0} onClick={handleBack}>
              Back
            </PrimaryButton>
            {activeStep === stepsBasedOnSelection.length - 1 ? (
              <PrimaryButton
                disabled={false}
                onClick={handleFinishClick}
                isLoading={isLoadingGettingPaymentLink}
                loadingText="Go To Payment"
              >
                Go To Payment
              </PrimaryButton>
            ) : (
              <PrimaryButton
                disabled={isNextButtonDisabled()}
                onClick={handleNext}
              >
                Next
              </PrimaryButton>
            )}
          </StepNavWrapper>
        </Fragment>
      }
    </>
  ) : (
    <></>
  )
}

const StepNavWrapper = styled(Box)`
  ${ROW_ALIGN_END_SPACE_B}
  padding-top: 16px;
`

export const StepWrapper = styled('div')`
  min-height: 200px;
  width: 100%;
  gap: 20px;
  ${COLUMN_ALIGN_START_JUSTIFY_CENTER};
`
