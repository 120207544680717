import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from 'store/toolkit'
export interface ContainerState {}
// The initial state of the LoginPage container
export const initialState: ContainerState = {}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
})

export const {
  actions: globalActions,
  reducer: globalReducer,
  name: sliceKey,
} = globalSlice
