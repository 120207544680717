import axiosInstance from 'api/axios'
import { WebhooksEndpoints } from 'api/endpoints/webhooks'
import { customToast } from 'app/components/toast/CustomToast'
import { AxiosResponse } from 'axios'

export function* getWebhookReq(params: {
  clientDomainUUID: string
  lastID: number
}) {
  if (!params.clientDomainUUID) {
    customToast.error(
      'Client Domain UUID is required, please select a client domain.',
    )
    return
  }
  const response: AxiosResponse = yield axiosInstance.get(
    WebhooksEndpoints.webhooksList,
    {
      params,
    },
  )
  return response
}
