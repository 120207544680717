import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { OAuthActions } from '../slice'
import EditIcon from '@mui/icons-material/Edit'

interface ActionProps {
  rowId: string
  actionHasBeenDone?: boolean
}
export const OAuthActionComponent = ({
  rowId,
  actionHasBeenDone,
}: ActionProps) => {
  const dispatch = useDispatch()
  const EditClickHandler = (item: string) => {
    dispatch(OAuthActions.setCrudSelectedItem(item))
  }
  return (
    <IconButton onClick={() => EditClickHandler(rowId)}>
      <EditIcon />
    </IconButton>
  )
}
