import { FC } from 'react'
import styled from 'styled-components'
import { ROW_ALIGN_CENTER_SPACE_B } from 'styles/globalStyles'

interface IPageHeaderProps {
  title: string
  children?: React.ReactNode
}

export const PageHeader: FC<IPageHeaderProps> = ({ title, children }) => {
  return (
    <TitleAndActionWrapper>
      <Title>{title}</Title>
      {children}
    </TitleAndActionWrapper>
  )
}

const Title = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
`
const TitleAndActionWrapper = styled('div')`
  ${ROW_ALIGN_CENTER_SPACE_B}
  width:100%;
  margin-bottom: 24px;
`
