import { Box } from '@mui/material'
import PrimaryButton from 'app/components/button/primaryButton'
import { Modal } from 'app/components/modal'
import { AppPages } from 'app/constants'
import { loginActions } from 'app/containers/Login/slice'
import { useDispatch, useSelector } from 'react-redux'
import history from 'router/history'
import { styled } from '@mui/material'
import { COLUMN_CENTER } from 'styles/globalStyles'
import { H3_BOLD, TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import { LoginSelectors } from 'app/containers/Login/selectors'

export const UnAuthorizedModal = () => {
  const dispatch = useDispatch()
  const titleOfModal = 'Expired/Invalid Token'
  const unAuthorized = useSelector(LoginSelectors.unauthorized)

  const handleLogOutClick = () => {
    dispatch(loginActions.logout())
    history.push(AppPages.Login)
  }
  return (
    <CModal open={unAuthorized}>
      <ModalContainer>
        <Title>{titleOfModal}</Title>
        <Body>Your Token is Expired/Invalid, Please Try to Login Again</Body>
        <StyledButton onClick={handleLogOutClick}>
          Go to Login Page
        </StyledButton>
      </ModalContainer>
    </CModal>
  )
}

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 608px !important;
  }

  .MuiDialog-paper {
    max-width: 608px !important;
  }
`

const ModalContainer = styled(Box)`
  ${COLUMN_CENTER};
  gap: 40px;
`

const Title = styled('p')`
  ${H3_BOLD}
  margin: 0;
`

const Body = styled('p')`
  ${TEXT_LG_SEMIBOLD}
  margin: 0;
`

const StyledButton = styled(PrimaryButton)`
  width: 200px;
  height: 50px;
`
