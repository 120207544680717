import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/types'
import { initialState } from './slice'

export const oauthDomains = {
  root: (state: RootState) => state.oauth || initialState,
  oauths: (state: RootState) => state.oauth.oauth,
  displayModal: (state: RootState) => state.oauth.displayModal,
  displayDeleteModal: (state: RootState) => state.oauth.displayDeleteModal,
  crudSelectedItem: (state: RootState) => state.oauth.crudSelectedItem,
  isLoadingOauth: (state: RootState) => state.oauth.isLoadingOauth,
  ClientDomainUuid: (state: RootState) => state.apiKeys.clientDomainUUID,
}

export const oauthSelectors = {
  root: createSelector(oauthDomains.root, root => root),
  oauths: createSelector(oauthDomains.oauths, oauth => oauth),
  displayModal: createSelector(
    oauthDomains.displayModal,
    displayModal => displayModal,
  ),
  displayDeleteModal: createSelector(
    oauthDomains.displayDeleteModal,
    displayDeleteModal => displayDeleteModal,
  ),
  crudSelectedItem: createSelector(
    oauthDomains.oauths,
    oauthDomains.crudSelectedItem,
    (oauths, crudSelectedItem) => {
      if (crudSelectedItem) {
        return oauths.find(oauth => oauth.uuid === crudSelectedItem)
      }
      return undefined
    },
  ),
  isLoadingOauth: createSelector(
    oauthDomains.isLoadingOauth,
    isLoadingOauth => isLoadingOauth,
  ),
  ClientDomainUuid: createSelector(
    oauthDomains.ClientDomainUuid,
    ClientDomainUuid => ClientDomainUuid,
  ),
}
