import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'
import { KYC_PROPERTIES } from './constants'
import { RequiredOrOptionalFieldsInputValueType } from './types'

export const packagesDomains = {
  root: (state: RootState) => state.packages || initialState,
  purchasedPackages: (state: RootState) => state.packages.purchasedPackages,
  newSourceKey: (state: RootState) => state.packages.newSourceKey,
  selectedPackagePrice: (state: RootState) =>
    state.packages.selectedPackagePrice,
  displayModal: (state: RootState) => state.packages.displayModal,
  isLoadingPackages: (state: RootState) => state.packages.isLoadingPackages,
  isLoadingFieldPackages: (state: RootState) =>
    state.packages.isLoadingFieldPackages,
  paymentProviders: (state: RootState) => state.packages.paymentProviders,
  detailsModalSelectedItem: (state: RootState) =>
    state.packages.detailsModalSelectedItem,
  setIsLoadingGettingPaymentLink: (state: RootState) =>
    state.packages.isLoadingGettingPaymentLink,
  isFetchingPackagePrice: (state: RootState) =>
    state.packages.isFetchingPackagePrice,
}

export const packagesSelectors = {
  root: createSelector(packagesDomains.root, root => root),
  purchasedPackages: createSelector(
    packagesDomains.purchasedPackages,
    packages => packages,
  ),
  newSourceKey: createSelector(
    packagesDomains.newSourceKey,
    newSourceKey => newSourceKey,
  ),
  selectedPackagePrice: createSelector(
    packagesDomains.selectedPackagePrice,
    packages => packages,
  ),
  fields: ({ required }: { required: boolean }) =>
    createSelector(packagesDomains.newSourceKey, newSourceKey =>
      required ? newSourceKey.requiredFields : newSourceKey.optionalFields,
    ),
  displayModal: createSelector(
    packagesDomains.displayModal,
    displayModal => displayModal,
  ),
  newSourceKeySelectedFields: createSelector(
    packagesDomains.newSourceKey,
    // map over newSourceKey values and if the selectedChildItem was not empty return that
    // and if it was empty and checked property was true return item key and else return nothing
    newSourceKey => {
      const selectedFields = Object.entries(newSourceKey.requiredFields).map(
        ([key, value]: [string, RequiredOrOptionalFieldsInputValueType]) => {
          if (value.selectedChildItem) {
            return value.selectedChildItem
          } else if (value.checked) {
            return KYC_PROPERTIES.find(property => property.label === key)
              ?.value
          }
          return undefined
        },
      )
      const filteredResult = selectedFields?.filter(Boolean) as string[]
      //if any array is in the selectedFields, flatten it
      if (filteredResult.some((item: any) => Array.isArray(item))) {
        return filteredResult.flat()
      }
      return filteredResult
    },
  ),
  newSourceKeyOptionalFields: createSelector(
    packagesDomains.newSourceKey,
    newSourceKey => {
      const selectedFields = Object.entries(newSourceKey.optionalFields).map(
        ([key, value]: [string, RequiredOrOptionalFieldsInputValueType]) => {
          if (value.selectedChildItem) {
            return value.selectedChildItem
          } else if (value.checked) {
            return KYC_PROPERTIES.find(property => property.label === key)
              ?.value
          }
          return undefined
        },
      )
      const filteredResult = selectedFields?.filter(Boolean) as string[]
      //if any array is in the selectedFields, flatten it
      if (filteredResult.some((item: any) => Array.isArray(item))) {
        return filteredResult.flat()
      }
      return filteredResult
    },
  ),
  isLoadingPackages: createSelector(
    packagesDomains.isLoadingPackages,
    isLoadingPackages => isLoadingPackages,
  ),
  isLoadingFieldPackages: createSelector(
    packagesDomains.isLoadingFieldPackages,
    isLoadingFieldPackages => isLoadingFieldPackages,
  ),
  paymentProviders: createSelector(
    packagesDomains.paymentProviders,
    paymentProviders => paymentProviders,
  ),
  detailsModalSelectedItem: createSelector(
    packagesDomains.detailsModalSelectedItem,
    packagesDomains.purchasedPackages,
    (detailsModalSelectedItem, purchasedPackages) => {
      return purchasedPackages.find(
        purchasedPackage => purchasedPackage.uuid === detailsModalSelectedItem,
      )
    },
  ),
  getPurchasePackageByUUID: (uuid: string) =>
    createSelector(packagesDomains.purchasedPackages, purchasedPackages =>
      purchasedPackages.find(
        purchasedPackage => purchasedPackage.uuid === uuid,
      ),
    ),
  isLoadingGettingPaymentLink: createSelector(
    packagesDomains.setIsLoadingGettingPaymentLink,
    isLoadingGettingPaymentLink => isLoadingGettingPaymentLink,
  ),
  isFetchingPackagePrice: createSelector(
    packagesDomains.isFetchingPackagePrice,
    isFetchingPackagePrice => isFetchingPackagePrice,
  ),
}
