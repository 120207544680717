import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { RolesActions } from '../slice'
import { RolesEndpoints } from 'api/endpoints/roles'

export function* getRolesReq() {
  const response: AxiosResponse = yield axiosInstance.get(
    RolesEndpoints.rolesList,
  )
  return response
}

export function* addClientRoleReq(
  action: ReturnType<typeof RolesActions.addClientRole>,
) {
  const response: AxiosResponse = yield axiosInstance.post(
    RolesEndpoints.addClientRole,
    action.payload,
  )
  return response
}

export function* getAllClientsReq() {
  const response: AxiosResponse = yield axiosInstance.get(
    RolesEndpoints.getAllClients,
  )
  return response
}

export function* removeClientReq(corepassID: string) {
  const response: AxiosResponse = yield axiosInstance.post(
    RolesEndpoints.removeClient,
    { corepassID },
  )
  return response
}
