export function constructQueryParamsFromObject(
  params: Record<string, any> | undefined,
) {
  if (!params) {
    return ''
  }

  const queryString = Object.keys(params)
    .filter(key => {
      const value = params[key]
      // Check if the value is an array
      if (Array.isArray(value)) {
        // If it's an array, construct multiple query params with the same key
        return !!value.length // Only include non-empty arrays
      }
      return !!value // Include non-empty values
    })
    .map(key => {
      const value = params[key]
      // If the value is an array, construct multiple query params with the same key
      if (Array.isArray(value)) {
        return value
          .map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
          .join('&')
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')

  return `?${queryString}`
}
