import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PackagesActions } from '../slice'
import { packagesSelectors } from '../selectors'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import SortableTable from 'app/components/table/sortableTable'
import { sourceKeyColumns } from '../constants'
import { DetailsActionComponent } from './detailsActionButton'
import { RenewActionComponent } from './renewActionButton'

export const PackagesTable = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(PackagesActions.getPurchasedPackages())
  }, [dispatch])

  const purchasedPackages = useSelector(packagesSelectors.purchasedPackages)
  const isLoadingPackages = useSelector(packagesSelectors.isLoadingPackages)
  const applications = useSelector(applicationsSelectors.applications)
  const purchasedPackagesWithDomainNames = purchasedPackages?.map?.(
    purchasedPackage => {
      const domainName = applications?.find(
        item => item.uuid === purchasedPackage.clientDomainUUID,
      )?.domain
      return {
        ...purchasedPackage,
        domainName,
      }
    },
  )

  const speceficRowStyleBasedOnCellValue = {
    actionComponent: [DetailsActionComponent, RenewActionComponent],
    cellKey: '',
    cellValue: '',
  }

  return (
    <>
      <SortableTable
        name="SourceKey-table"
        data={purchasedPackagesWithDomainNames}
        columns={sourceKeyColumns}
        speceficRowStyleBasedOnCellValue={speceficRowStyleBasedOnCellValue}
        isLoading={isLoadingPackages && !(purchasedPackages?.length > 0)}
        showNumberOfPages={false}
      />
    </>
  )
}
