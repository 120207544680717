import { APP_TYPES_OPTIONS } from 'app/containers/Applications/constants'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material'
import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
} from 'styles/globalStyles'
interface Props {}

export const Summary: FC<Props> = props => {
  const newAppValues = useSelector(applicationsSelectors.newApppValues)
  return (
    <DeatilsContainer>
      {Object.keys(newAppValues).map(key => {
        return (
          <InputWrapper key={key}>
            {newAppValues.platform === APP_TYPES_OPTIONS.MOBILE ? (
              key === 'domain' ? undefined : (
                <>
                  <InputLabel>{key}:</InputLabel>
                  <p>{newAppValues[key as keyof typeof newAppValues]}</p>
                </>
              )
            ) : key === 'bundleId' ? undefined : (
              <>
                <InputLabel>{key}:</InputLabel>
                <p>{newAppValues[key as keyof typeof newAppValues]}</p>
              </>
            )}
          </InputWrapper>
        )
      })}
    </DeatilsContainer>
  )
}

const DeatilsContainer = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
`

const InputWrapper = styled('div')`
  ${ROW_CENTER}
  div {
    width: fit-content;
  }
`

const InputLabel = styled('p')`
  min-width: 100px;
  font-weight: bold;
`
