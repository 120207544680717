import { SelectChangeEvent, Skeleton } from '@mui/material'
import BaseDropdownInput from 'app/components/input/dropdownInput'
import { profileSelectors } from 'app/containers/Profile/selectors'
import { profileActions } from 'app/containers/Profile/slice'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ROW_JUSTIFY_END_ALIGN_CENTER } from 'styles/globalStyles'
import { isNonEmptyTruthy } from 'utils/helpers'

export const Header = () => {
  const dispatch = useDispatch()

  const organizationList = useSelector(profileSelectors.organizations)
  const selectedOrganization = useSelector(
    profileSelectors.selectedOrganization,
  )

  const handleOrganizationChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(
      profileActions.setSelectedOrganization(event.target.value as string),
    )
  }
  return (
    <Wrapper>
      <HeaderWrapper>
        {isNonEmptyTruthy(organizationList) ? (
          <SelectOrganizationDropdown
            options={organizationList.map(item => {
              return {
                value: item.organizationUUID,
                label: item.organizationName,
              }
            })}
            value={selectedOrganization}
            onChange={handleOrganizationChange}
          />
        ) : (
          <Skeleton height={48} width={150} />
        )}
      </HeaderWrapper>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100vw;
  height: 60px !important;
  z-index: 99;
`
const HeaderWrapper = styled('header')`
  width: calc(100vw - 32px);
  max-width: calc(100vw - 32px);
  margin: 5px 16px;
  padding: 12px;
  ${ROW_JUSTIFY_END_ALIGN_CENTER}
  background: rgb(19,23,34);
  height: 56px !important;

  background: linear-gradient(
    90deg,
    var(--mirage) 0%,
    var(--causeway) 35%,
    var(--turquoise) 100%
  );
  border-radius: 16px;
`

const SelectOrganizationDropdown = styled(BaseDropdownInput)`
  height: 36px !important;
`
