import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { ApplicationsActions } from '../slice'
import SearchIcon from '@mui/icons-material/Search'
import DomainVerificationIcon from '@mui/icons-material/DomainVerification'

interface ActionProps {
  rowId: string
  actionHasBeenDone?: boolean
}
export const DomainActionComponent = ({
  rowId,
  actionHasBeenDone,
}: ActionProps) => {
  const dispatch = useDispatch()
  const handleCheckClick = (uuid: string) => {
    dispatch(ApplicationsActions.checkDomainStatus(uuid))
  }
  return (
    <>
      <IconButton
        onClick={() =>
          !actionHasBeenDone ? handleCheckClick(rowId) : undefined
        }
      >
        {!actionHasBeenDone ? <SearchIcon /> : <DomainVerificationIcon />}{' '}
      </IconButton>
    </>
  )
}
