import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/types'
import { initialState } from './slice'

export const rolesDomains = {
  root: (state: RootState) => state.roles || initialState,
  roles: (state: RootState) => state.roles.roles,
  isRolesModalDisplayed: (state: RootState) =>
    state.roles.isRolesModalDisplayed,
  isLoading: (state: RootState) => state.roles.isLoading,
  error: (state: RootState) => state.profile.error,
  allClients: (state: RootState) => state.roles.allClients,
  selectedClient: (state: RootState) => state.roles.selectedClient,
}

export const rolesSelectors = {
  root: createSelector(rolesDomains.root, root => root),
  roles: createSelector(rolesDomains.roles, roles => roles),
  isRolesModalDisplayed: createSelector(
    rolesDomains.isRolesModalDisplayed,
    displayModal => displayModal,
  ),
  isLoading: createSelector(rolesDomains.isLoading, isLoading => isLoading),
  error: createSelector(rolesDomains.error, error => error),
  allClients: createSelector(rolesDomains.allClients, allClients => allClients),
  selectedClient: createSelector(
    // find the selected client from allClients corepassID
    rolesDomains.allClients,
    rolesDomains.selectedClient,
    (allClients, selectedClient) =>
      allClients.find(client => client.corepassID === selectedClient),
  ),
}
