export const rolesColumnsWithoutModify = [
  { id: 'corepassID', label: 'Corepass ID' },
  { id: 'role', label: 'Role' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
]

export const rolesColumnsWithModify = [
  ...rolesColumnsWithoutModify,
  { id: 'action', label: 'Modify', actionKey: 'corepassID' },
]
