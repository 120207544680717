import { useDispatch, useSelector } from 'react-redux'
import { rolesSelectors } from '../../../selectors'
import { RolesActions } from '../../../slice'
import { RolesForm } from './content'
import { Modal } from 'app/components/modal'

export default function AddRolesModal() {
  const dispatch = useDispatch()
  const displayModal = useSelector(rolesSelectors.isRolesModalDisplayed)

  const handleModalClose = () => dispatch(RolesActions.setDisplayModal(false))

  return (
    <Modal open={displayModal} onClose={handleModalClose}>
      <RolesForm />
    </Modal>
  )
}
