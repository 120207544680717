import { FC } from 'react'
import { styled } from '@mui/material'
import { COLUMN_ALIGN_START_JUSTIFY_START } from 'styles/globalStyles'
import CheckboxListSecondary from '../../modals/expandableList'

interface Props {
  required: boolean
}
export const RequiredFields: FC<Props> = ({ required }) => {
  return (
    <DeatilsContainer>
      <CheckboxListSecondary required={required} />
    </DeatilsContainer>
  )
}

const DeatilsContainer = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
`
