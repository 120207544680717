/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'

import { InjectedReducersType } from 'store/types/injector-typings'
import { globalReducer } from './slice'
import { profileReducer } from 'app/containers/Profile/slice'
import { ApplicationsReducer } from 'app/containers/Applications/slice'
import { PackagesReducer } from 'app/containers/Package/slice'
import { ApiKeysReducer } from 'app/containers/ApiKey/slice'
import { OAuthReducer } from 'app/containers/OAuth/slice'
import { RolesReducer } from 'app/containers/Roles/slice'
import { webhookReducer } from 'app/containers/Webhook/slice'
import { loginReducer } from 'app/containers/Login/slice'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error

  return combineReducers({
    ...(Object.keys(injectedReducers).length !== 0 && injectedReducers),
    global: globalReducer,
    login: loginReducer,
    profile: profileReducer,
    applications: ApplicationsReducer,
    packages: PackagesReducer,
    apiKeys: ApiKeysReducer,
    oauth: OAuthReducer,
    roles: RolesReducer,
    webhook: webhookReducer,
  })
}
