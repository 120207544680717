/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from 'store/toolkit'
import { ApiKey, ApiKeysState } from './types'

// The initial state of the Applications container
export const initialState: ApiKeysState = {
  apiKeys: [],
  clientDomainUUID: '',
  displayModal: false,
  isLoadingApiKeys: false,
}

const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    getApiKeys(state) {},
    setApiKeys(state, action: PayloadAction<ApiKey[]>) {
      state.apiKeys = action.payload
    },
    setClientDomainUuid(state, action: PayloadAction<string>) {
      state.clientDomainUUID = action.payload
    },
    setDisplayModal(state, action: PayloadAction<boolean>) {
      state.displayModal = action.payload
    },
    createApiKey(state) {},
    setIsLoadingApiKeys(state, action: PayloadAction<boolean>) {
      state.isLoadingApiKeys = action.payload
    },
  },
})

export const {
  actions: ApiKeysActions,
  reducer: ApiKeysReducer,
  name: sliceKey,
} = apiKeysSlice

// export const useApplicationsSlice = () => {
//   useInjectReducer({ key: sliceKey, reducer: ApplicationsReducer });
//   useInjectSaga({ key: sliceKey, saga: applicationsSaga });
//   return { ApplicationsActions };
// };
