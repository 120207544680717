import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'
import { applicationsDomains } from '../Applications/selectors'

export const apiKeysDomains = {
  root: (state: RootState) => state.apiKeys || initialState,
  apiKeys: (state: RootState) => state.apiKeys.apiKeys,
  ClientDomainUuid: (state: RootState) => state.apiKeys.clientDomainUUID,
  displayModal: (state: RootState) => state.apiKeys.displayModal,
  isLoadingApiKeys: (state: RootState) => state.apiKeys.isLoadingApiKeys,
}

export const apiKeysSelectors = {
  root: createSelector(apiKeysDomains.root, root => root),
  apiKeys: createSelector(apiKeysDomains.apiKeys, apiKeys => apiKeys),
  ClientDomainUuid: createSelector(
    apiKeysDomains.ClientDomainUuid,
    ClientDomainUuid => ClientDomainUuid,
  ),
  displayModal: createSelector(
    apiKeysDomains.displayModal,
    displayModal => displayModal,
  ),
  isLoadingApiKeys: createSelector(
    apiKeysDomains.isLoadingApiKeys,
    isLoadingApiKeys => isLoadingApiKeys,
  ),
  apiKeysWithDomain: createSelector(
    [apiKeysDomains.apiKeys, applicationsDomains.applications],
    (apiKeys, applications) => {
      return apiKeys.map(apiKey => {
        const domain = applications.find(
          app => app.uuid === apiKey.clientDomainUUID,
        )?.domain
        return { ...apiKey, domain }
      })
    },
  ),
}
