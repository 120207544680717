import { PageHeader } from '../../components/common/globals/pageHeader'
import AddApiKeyModal from './components/modals/addOauth'
import { OAuthtable } from './components/Table'
import { useDispatch } from 'react-redux'
import { OAuthActions } from './slice'
import PrimaryButton from 'app/components/button/primaryButton'

export function OAuth() {
  const dispatch = useDispatch()
  const handleAddOauthClick = () => dispatch(OAuthActions.setDisplayModal(true))

  return (
    <>
      <PageHeader title="OAuth">
        <PrimaryButton onClick={handleAddOauthClick}>Add OAuth</PrimaryButton>
      </PageHeader>
      <OAuthtable />
      <AddApiKeyModal />
    </>
  )
}
