import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { DomainSelect } from '../domainSelect'
import { ApiKeysActions } from '../../slice'
import { apiKeysSelectors } from '../../selectors'
import { COLUMN_ALIGN_END_JUSTIFY_CENTER } from 'styles/globalStyles'
import styled from 'styled-components'
import { Modal } from 'app/components/modal'
import PrimaryButton from 'app/components/button/primaryButton'

export default function AddApplicationModal() {
  const dispatch = useDispatch()
  const displayModal = useSelector(apiKeysSelectors.displayModal)
  const handleModalClose = () => dispatch(ApiKeysActions.setDisplayModal(false))
  const handleCreateApoClick = () => {
    dispatch(ApiKeysActions.createApiKey())
  }
  const clientDomainUUID = useSelector(apiKeysSelectors.ClientDomainUuid)

  return (
    <Modal open={displayModal} onClose={handleModalClose}>
      <ModalContentContainer>
        <DomainSelect />
        <PrimaryButton
          onClick={handleCreateApoClick}
          disabled={!clientDomainUUID}
        >
          Create Api Key
        </PrimaryButton>
      </ModalContentContainer>
    </Modal>
  )
}

const ModalContentContainer = styled(Box)`
  ${COLUMN_ALIGN_END_JUSTIFY_CENTER}
  gap:20px
`
