import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PageHeader } from '../../components/common/globals/pageHeader'

import AddPackageModal from './components/modals/addPackage'
import { PackagesTable } from './components/Table'
import { ApplicationsActions } from '../Applications/slice'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { PackagesActions } from './slice'
import { AppPages } from 'app/constants'
import { DetailsModal } from './components/modals/details'
import PrimaryButton from 'app/components/button/primaryButton'

interface Props {}

export function Packages(props: Props) {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    dispatch(ApplicationsActions.getApplications())
  }, [dispatch])

  const navigate = useNavigate()

  const handleAddPackageClick = () =>
    dispatch(PackagesActions.setDisplayModal(true))

  const orderId = searchParams.get('orderId') || ''

  useEffect(() => {
    // if pathanem is checkout and there is an orderId in the url
    if (orderId && pathname.includes(AppPages.Checkout)) {
      dispatch(PackagesActions.getPaymentStatus({ orderId }))
    }
    navigate(AppPages.Packages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('orderId')])

  return (
    <>
      <PageHeader title="Packages">
        <PrimaryButton onClick={handleAddPackageClick}>
          Add Package
        </PrimaryButton>
      </PageHeader>
      <PackagesTable />
      <DetailsModal />
      <AddPackageModal />
    </>
  )
}
