import * as Yup from 'yup'

const regexp = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  domain: Yup.string().url().required('Required'),
  bundleId: Yup.string()
    .matches(regexp, 'Invalid Domain ID')
    .required('Required'),
  framework: Yup.string().required('Required'),
})
