import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/types'
import { initialState } from './slice'

export const applicationsDomains = {
  root: (state: RootState) => state.applications || initialState,
  applications: (state: RootState) => state.applications.applications,
  newApppValues: (state: RootState) => state.applications.newApppValues,
  displayModal: (state: RootState) => state.applications.displayModal,
  isLoadingApplications: (state: RootState) =>
    state.applications.isLoadingApplications,
}

export const applicationsSelectors = {
  root: createSelector(applicationsDomains.root, root => root),
  applications: createSelector(
    applicationsDomains.applications,
    applications => applications,
  ),
  newApppValues: createSelector(
    applicationsDomains.newApppValues,
    newApppValues => newApppValues,
  ),
  displayModal: createSelector(
    applicationsDomains.displayModal,
    displayModal => displayModal,
  ),
  domains: createSelector(applicationsDomains.applications, applications => {
    return applications?.map?.(({ domain, uuid }) => {
      return { domain, uuid }
    })
  }),
  isLoadingApplications: createSelector(
    applicationsDomains.isLoadingApplications,
    isLoadingApplications => isLoadingApplications,
  ),
  domainNameBaseOnClientDomainUUID: (clientDomainUUID: string) =>
    createSelector(applicationsDomains.applications, applications => {
      return applications?.find(item => item.uuid === clientDomainUUID)?.domain
    }),
}
