import { Box, styled } from '@mui/material'
import { FC } from 'react'
import { ROW_JUSTIFY_START_ALIGN_CENTER } from 'styles/globalStyles'

interface ItemWidgetProps {
  label: string
  value: number | string | undefined
}

export const Item: FC<ItemWidgetProps> = ({ label, value }) => (
  <ItemConatiner>
    <ItemLabel>{label}</ItemLabel>
    <ItemValue>{value}</ItemValue>
  </ItemConatiner>
)

export const ItemConatiner = styled(Box)`
  ${ROW_JUSTIFY_START_ALIGN_CENTER}
  gap: 8px;
  width: 100%;
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const ItemValue = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  min-height: 35px;
  color: ${({ theme }) => theme.palette.text.primary};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: 8px;
  border-radius: 8px;
  word-wrap: break-word;
  width: calc(100% - 128px);
`
