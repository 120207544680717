import { styled } from '@mui/material'
import { COLUMN, ROW_JUSTIFY_START_ALIGN_CENTER } from 'styles/globalStyles'

export const Wrapper = styled('div')`
  width: 100%;
  margin-top: 48px;
  ${COLUMN}
  flex-wrap: wrap;
`
export const Card = styled('div')`
  ${ROW_JUSTIFY_START_ALIGN_CENTER}
  padding: 24px;
  width: 100%;
  margin: 8px;
  background: var(--white);
  border: 1px solid var(--link-water);
  border-radius: 16px;
  gap: 10px;
  @media (max-width: 1010px) {
    width: 100%;
  }
`
export const CardTitle = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: var(--black);
  text-transform: capitalize;
`

export const InfoAndValueWrapper = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
`
