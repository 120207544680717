import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { PackagesActions } from '../slice'
import { PackagesEndpoints } from 'api/endpoints/packages'

export function* getPurchasedPackagesList() {
  const response: AxiosResponse = yield axiosInstance.get(
    PackagesEndpoints.purchasedPackagesList,
  )
  return response
}

export function* createSourceKeyReq(createSourceKeyReqData: any) {
  const response: AxiosResponse = yield axiosInstance.post(
    PackagesEndpoints.createSourceKey,
    createSourceKeyReqData,
  )
  return response
}

export function* getPaymentStatusReq(
  action: ReturnType<typeof PackagesActions.getPaymentStatus>,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    PackagesEndpoints.paymentStatus,
    { params: { orderUUID: action.payload.orderId } },
  )

  return response
}

export function* getPaymentProviderList() {
  const response: AxiosResponse = yield axiosInstance.get(
    PackagesEndpoints.paymentProviderList,
  )
  return response
}

export function* calculatePriceReq(
  action: ReturnType<typeof PackagesActions.calculatePrice>,
) {
  const quantity = action.payload.quantity || 0
  const fields = action.payload.fields
  const optionalfields = action.payload.optionalfields

  const response: AxiosResponse = yield axiosInstance.get(
    PackagesEndpoints.calculatePrice(fields, optionalfields, quantity),
  )

  return response
}
