import { useEffect } from 'react'
import { UnAuthorizedModal } from './components/modal'
import { useDispatch } from 'react-redux'
import { profileActions } from '../Profile/slice'
import { useLocation } from 'react-router-dom'
import { AppPages } from 'app/constants'
import { getTokenAndOrganizationFromStorage } from 'api/axios'

function InitializeApp() {
  const dispatch = useDispatch()
  const [authToken] = getTokenAndOrganizationFromStorage()
  const { pathname } = useLocation()

  useEffect(() => {
    if (authToken) {
      dispatch(profileActions.getKYC())
      dispatch(profileActions.getOrganizations())
    }
  }, [authToken, dispatch])
  return pathname !== AppPages.Login ? <UnAuthorizedModal /> : undefined
}

export default InitializeApp
