import { RolesTable } from './components/Table'
import AddRolesModal from './components/modals/addRoles'
import { useDispatch } from 'react-redux'
import { RolesActions } from './slice'
import PrimaryButton from 'app/components/button/primaryButton'
import { PageHeader } from 'app/components/common/globals/pageHeader'

interface Props {}
export function Roles(props: Props) {
  const dispatch = useDispatch()

  const handleAddRoleClick = () => {
    dispatch(
      RolesActions.setModalAndSelectedClient({
        displayModal: true,
        selectedClient: undefined,
      }),
    )
  }

  return (
    <>
      <PageHeader title="Roles">
        <PrimaryButton onClick={handleAddRoleClick}>Add Roles</PrimaryButton>
      </PageHeader>
      <RolesTable />
      <AddRolesModal />
    </>
  )
}
