import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationsActions } from '../slice'
import { applicationsSelectors } from '../selectors'
import { DOMAIN_STATUS, domainColumns } from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { DomainActionComponent } from './domainActionComponent'

export const ApplicationsTable = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(ApplicationsActions.getApplications())
  }, [dispatch])

  const applications = useSelector(applicationsSelectors.applications)
  const isLoadingApplications = useSelector(
    applicationsSelectors.isLoadingApplications,
  )

  const speceficRowStyleBasedOnCellValue = {
    actionComponent: [DomainActionComponent],
    cellKey: 'status',
    cellValue: DOMAIN_STATUS.VERIFIED,
  }

  return (
    <>
      <SortableTable
        name="domain-table"
        data={applications}
        columns={domainColumns}
        speceficRowStyleBasedOnCellValue={speceficRowStyleBasedOnCellValue}
        isLoading={isLoadingApplications && !(applications?.length > 0)}
        showNumberOfPages={false}
      />
    </>
  )
}
