import CustomizedSteppers from '../stepper/index'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationsActions } from '../../slice'
import { applicationsSelectors } from '../../selectors'
import { Modal } from 'app/components/modal'

export default function AddApplicationModal() {
  const dispatch = useDispatch()
  const displayModal = useSelector(applicationsSelectors.displayModal)
  const handleModalClose = () =>
    dispatch(ApplicationsActions.setDisplayModal(false))

  return (
    <Modal open={displayModal} onClose={handleModalClose}>
      <CustomizedSteppers />
    </Modal>
  )
}
