import { styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { webhookSelectors } from '../selector'
import SortableTable from 'app/components/table/sortableTable'
import { columns } from '../constants'

export const WebhooksTable = () => {
  const data = useSelector(webhookSelectors.webhook)
  const isLoadingWebhook = useSelector(webhookSelectors.webhookLoading)

  const parseResponse = (response: string) => {
    const parsedResponse = JSON.parse(response)
    return {
      success: parsedResponse.success,
      message: parsedResponse.message || parsedResponse.error.message,
    }
  }

  const tableData =
    data?.map(item => ({
      type: item.type,
      orderEventType: item.orderEventType,
      response: parseResponse(item.response).message,
      success: parseResponse(item.response).success.toString(),
      url: item.url,
      createdAt: item.createdAt,
    })) || []

  return (
    <Wrapper>
      <SortableTable
        name="webhook-table"
        data={tableData}
        columns={columns}
        isLoading={isLoadingWebhook}
        showNumberOfPages={false}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
