import { configureStore } from '@reduxjs/toolkit'
import { createInjectorsEnhancer } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'

import { createReducer } from './reducers'
import { runSagas } from './runSagas'
import { RootState } from './types'

const reduxSagaMonitorOptions = {}
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
export const { run: runSaga } = sagaMiddleware
export function configureAppStore(_initialState: RootState | {} = {}) {
  // Create the store with saga middleware
  const middlewares = [sagaMiddleware]
  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ]

  const store = configureStore({
    reducer: createReducer(),
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      ...middlewares,
    ],
    enhancers,
  })
  runSagas()

  // Make reducers hot reloadable, see http://mxs.is/googmo
  // if (module.hot) {
  //   module.hot.accept("./reducers", () => {
  //     forceReducerReload(store);
  //   });
  // }
  return store
}
