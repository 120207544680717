import { APP_TYPES } from 'app/containers/Applications/constants'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { ApplicationsActions } from 'app/containers/Applications/slice'
import { ApplicationsState } from 'app/containers/Applications/types'
import { ChangeEvent, FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { ROW_CENTER } from 'styles/globalStyles'
import { CRadioGroup } from 'app/components/radio-group'
interface Props {}
export const ChooseType: FC<Props> = props => {
  const dispatch = useDispatch()
  const { platform: selectedPlatform } = useSelector(
    applicationsSelectors.newApppValues,
  )
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      ApplicationsActions.setNewAppValues({
        [event.target.name as keyof ApplicationsState['newApppValues']]:
          event.target.value,
      }),
    )
  }
  useEffect(() => {
    dispatch(ApplicationsActions.getEncrytions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <RowContainer>
        <div>Platform:</div>
        <CRadioGroup
          name="platform"
          options={APP_TYPES.map(item => ({
            label: item,
            value: item,
          }))}
          value={selectedPlatform}
          handleChange={handleChange}
        />
      </RowContainer>
    </>
  )
}

const RowContainer = styled('div')`
  ${ROW_CENTER}
  gap: 20px;
  & > *:first-of-type {
    width: 150px;
  }
`
