/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { Application, ContainerState } from './types'
import { createSlice } from 'store/toolkit'

// The initial state of the Applications container
export const initialState: ContainerState = {
  applications: [],
  newApppValues: {
    platform: 'WEB',
    name: '',
    domain: '',
    bundleId: '',
    framework: '',
  },
  displayModal: false,
  isLoadingApplications: false,
}

const applicationsSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    getApplications(state) {},
    getEncrytions(state) {},
    setApplications(state, action: PayloadAction<Application[]>) {
      state.applications = action.payload
    },
    setNewAppValues(
      state,
      action: PayloadAction<ContainerState['newApppValues']>,
    ) {
      state.newApppValues = { ...state.newApppValues, ...action.payload }
    },
    createNewApplication(
      state,
      action: PayloadAction<ContainerState['newApppValues']>,
    ) {},
    setDisplayModal(state, action: PayloadAction<boolean>) {
      state.displayModal = action.payload
    },
    setIsLoadingApplications(state, action: PayloadAction<boolean>) {
      state.isLoadingApplications = action.payload
    },
    checkDomainStatus(state, action: PayloadAction<string>) {},
  },
})

export const {
  actions: ApplicationsActions,
  reducer: ApplicationsReducer,
  name: sliceKey,
} = applicationsSlice

// export const useApplicationsSlice = () => {
//   useInjectReducer({ key: sliceKey, reducer: ApplicationsReducer });
//   useInjectSaga({ key: sliceKey, saga: applicationsSaga });
//   return { ApplicationsActions };
// };
