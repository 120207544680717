import { CRadioGroup } from 'app/components/radio-group'
import { SELECTABLE_PACKAGE_TYPES } from 'app/containers/Package/constants'
import { packagesSelectors } from 'app/containers/Package/selectors'
import { PackagesActions } from 'app/containers/Package/slice'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
} from 'styles/globalStyles'

interface Props {}

export const PackageType: FC<Props> = props => {
  const dispatch = useDispatch()
  const { plan } = useSelector(packagesSelectors.newSourceKey)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(PackagesActions.setPackageType(event.target.value))
  }

  return (
    <DeatilsContainer>
      <RowContainer>
        <Label>Select your package type:</Label>
        <CRadioGroup
          name="plan"
          value={plan}
          handleChange={handleChange}
          options={Object.values(SELECTABLE_PACKAGE_TYPES).map(item => ({
            label: item,
            value: item,
          }))}
        />
      </RowContainer>
    </DeatilsContainer>
  )
}

const DeatilsContainer = styled.div`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
`

const RowContainer = styled.div`
  ${ROW_CENTER}
  gap: 20px;
  & > *:first-child {
    width: 150px;
    font-weight: 700;
  }
`

const Label = styled.div``
