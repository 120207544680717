import { put, select, takeLatest } from 'redux-saga/effects'
import { webhookActions } from './slice'
import { AxiosResponse } from 'axios'

import { getWebhookReq } from './provider'
import { webhookSelectors } from './selector'

export function* getWebhook() {
  // just for test
  const fakeClientDomainUUID = '4bb96e2d-ed50-4c5e-a944-10b87918e8e0'

  yield put(webhookActions.setWebhookLoading(true))
  const clientDomainUUID: string = yield select(
    webhookSelectors.selectedClientDomainUUID,
  )
  try {
    const lastID: number = yield select(webhookSelectors.lastWebhookId)

    const response: AxiosResponse = yield getWebhookReq({
      clientDomainUUID,
      lastID,
    })
    yield put(webhookActions.setWebhook(response?.data?.data))
  } catch (error: any) {
    console.log(error)
    const errorObj =
      error?.error?.response?.data?.error ||
      error?.response?.data?.error ||
      error
    yield put(webhookActions.setWebhookError(errorObj))
  } finally {
    yield put(webhookActions.setWebhookLoading(false))
  }
}
export function* webhookSaga() {
  yield takeLatest(webhookActions.getWebhook.type, getWebhook)
}
