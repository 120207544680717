import {
  Autocomplete,
  Chip,
  AutocompleteRenderInputParams,
} from '@mui/material'
import { styled } from '@mui/material'
import { COLUMN_ALIGN_START_JUSTIFY_CENTER } from 'styles/globalStyles'
import { StyledTextField } from './style'

interface CInputMultipleProps {
  value: string[]
  onChange: (name: string, data: string[]) => void
  name: string
  label: string
  errors: any
  width?: string
}

export default function CInputMultiple({
  value,
  onChange,
  label,
  name,
  errors,
  width = '300px',
}: CInputMultipleProps) {
  return (
    <Container>
      <Autocomplete
        clearIcon={false}
        options={[]}
        freeSolo={true}
        multiple
        value={value}
        onChange={(e, data) => {
          onChange(name, data)
        }}
        renderTags={(value, props) =>
          value.map((option, index) => (
            /* eslint-disable react/jsx-key */
            <Chip label={option} {...props({ index })} />
          ))
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <StyledTextField
            label={label}
            {...params}
            variant="outlined"
            sx={{ width }}
          />
        )}
        sx={{ minWidth: width }}
      />
      <ErrorFieldContainer>
        {errors?.[name] && <ErrorField> {errors?.[name]} </ErrorField>}
      </ErrorFieldContainer>
    </Container>
  )
}

const Container = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_CENTER}
  position: relative;
`

const ErrorFieldContainer = styled('div')`
  position: absolute;
  bottom: -20px;
  height: 20px;
  width: 100%;
  overflow: hidden;
`
const ErrorField = styled('div')`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`
