import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectChangeEvent, styled } from '@mui/material'

import { RolesActions } from 'app/containers/Roles/slice'
import { rolesSelectors } from 'app/containers/Roles/selectors'

import BaseDropdownInput from 'app/components/input/dropdownInput'
import BaseInput from 'app/components/input/baseInput'
import PrimaryButton from 'app/components/button/primaryButton'

import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
  ROW_JUSTIFY_START_ALIGN_CENTER,
} from 'styles/globalStyles'
import { RolesFormData } from 'app/containers/Roles/types'
import { profileSelectors } from 'app/containers/Profile/selectors'

interface Props {}

export const RolesForm: FC<Props> = props => {
  const dispatch = useDispatch()

  const roles = useSelector(rolesSelectors.roles)
  const selectedClient = useSelector(rolesSelectors.selectedClient)
  const corepassID = useSelector(profileSelectors.kyc)
  const isClient = corepassID?.corepassID === selectedClient?.corepassID

  const [formData, setFormData] = useState<RolesFormData>(() => ({
    firstName: selectedClient?.firstName || '',
    lastName: selectedClient?.lastName || '',
    corepassID: selectedClient?.corepassID || '',
    role: selectedClient?.role || '',
  }))

  useEffect(() => {
    dispatch(RolesActions.getRoles())
  }, [dispatch])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleDropDownChange = (event: SelectChangeEvent<unknown>) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      role: event.target.value as string,
    }))
  }

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()
    selectedClient
      ? dispatch(RolesActions.updateClient(formData))
      : dispatch(RolesActions.addClientRole(formData))
    dispatch(RolesActions.setDisplayModal(false))
  }

  return (
    <Container>
      <RowContainer>
        <RowItemContainer>
          <div>First Name:</div>
          <BaseInput
            value={formData.firstName}
            onChange={handleInputChange}
            name="firstName"
            label="First Name"
          />
        </RowItemContainer>
        <RowItemContainer>
          <div>Last Name:</div>
          <BaseInput
            value={formData.lastName}
            onChange={handleInputChange}
            name="lastName"
            label="Last Name"
          />
        </RowItemContainer>
      </RowContainer>
      <RowContainer>
        <RowItemContainer>
          <div>CorepassID:</div>
          <BaseInput
            value={formData.corepassID}
            onChange={handleInputChange}
            name="corepassID"
            label="CoreID"
            disabled={!!selectedClient?.corepassID}
          />
        </RowItemContainer>
        <RowItemContainer>
          <div>Select Role:</div>
          <BaseDropdownInput
            value={formData.role}
            onChange={handleDropDownChange}
            name="role"
            label="Select Role"
            options={roles?.filter((value: string) => value !== '')}
            disabled={isClient}
          />
        </RowItemContainer>
      </RowContainer>

      <PrimaryButton
        type="submit"
        disabled={Object.values(formData).some(value => value === '')}
        onClick={handleSubmit}
      >
        Submit
      </PrimaryButton>
    </Container>
  )
}

const Container = styled('form')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
  gap: 32px;
`
const RowContainer = styled('div')`
  ${ROW_CENTER}
  gap: 32px;
  width: 100%;
`

const RowItemContainer = styled('div')`
  ${ROW_JUSTIFY_START_ALIGN_CENTER}
  width: 100%;
  gap: 12px;
  & > *:first-of-type {
    min-width: 100px !important;
    font-weight: 700;
  }
  & > div:first-of-type {
    min-width: fit-content;
  }
  & > div:last-child {
    width: 100%;
  }
`
