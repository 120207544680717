import { useDispatch, useSelector } from 'react-redux'
import { PackagesActions, initialNewSourceKey } from '../../slice'
import { packagesSelectors } from '../../selectors'
import CreatePackageStepper from '../stepper'
import { Modal } from 'app/components/modal'

export default function AddPackageModal() {
  const dispatch = useDispatch()
  const displayModal = useSelector(packagesSelectors.displayModal)

  const handleModalClose = () => {
    dispatch(PackagesActions.setDisplayModal(false))
    dispatch(PackagesActions.setNewPackageValues(initialNewSourceKey))
  }

  return (
    <Modal open={displayModal} onClose={handleModalClose}>
      <CreatePackageStepper />
    </Modal>
  )
}
