import { constructQueryParamsFromObject } from 'utils/objects'

export const PackagesEndpoints = {
  purchasedPackagesList: `source-key`,
  packagesList: `bundles`,
  fieldRelatedPackagesList: (fields: string) =>
    `bundle/detail-by-fields${constructQueryParamsFromObject({ fields })}`,
  createSourceKey: `order/create`,
  paymentStatus: `order/check-payment`,
  paymentProviderList: `payment/providers`,
  calculatePrice: (
    fields: string[],
    optionalfields: string[],
    quantity: number,
  ) => {
    return `bundle/calculate-price${constructQueryParamsFromObject({ fields, optionalfields, quantity })}`
  },
}
