import { AppDetail } from './components/stepper/steps/apiDetail'
import { ChooseType } from './components/stepper/steps/chooseType'
import { Summary } from './components/stepper/steps/summary'

export const APP_TYPES = ['WEB', 'MOBILE']

export const WEB_FRAMEWORK_OPTIONS = ['ReactJS', 'VueJS', 'Angular']
export const MOBILE_FRAMEWORK_OPTIONS = ['Flutter', 'ReactNative']

export const APP_TYPES_OPTIONS = {
  WEB: 'WEB',
  MOBILE: 'MOBILE',
}

export enum DOMAIN_STATUS {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}
export interface ApplicationsStep {
  label: string
  component: React.FC<any>
}

export interface ApplicationsStepProps {
  required?: boolean
}

export enum APP_STEPS_NAMES {
  SELECT_PLATFORM = 'Select Platform',
  ENTER_APPLICATION_DETAILS = 'Enter Application Details',
  SUMMARY_OF_INFORMATION = 'Summary of Information',
}
export const ADD_APP_STEPS: ApplicationsStep[] = [
  {
    label: APP_STEPS_NAMES.SELECT_PLATFORM,
    component: ChooseType,
  },
  {
    label: APP_STEPS_NAMES.ENTER_APPLICATION_DETAILS,
    component: AppDetail,
  },
  {
    label: APP_STEPS_NAMES.SUMMARY_OF_INFORMATION,
    component: Summary,
  },
]
export const domainColumns = [
  { id: 'name', label: 'Name' },
  { id: 'domain', label: 'Domain' },
  { id: 'framework', label: 'Framework' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Check', actionKey: 'uuid' },
]
