import { ConfirmPayment } from './components/stepper/steps/confirmPayment'
import { DomainSelect } from './components/stepper/steps/domainSelect'
import { QuantitySelection } from './components/stepper/steps/packageSelection'
import { PackageType } from './components/stepper/steps/packageType'
import { RequiredFields } from './components/stepper/steps/requiredFields'
import { PackageStep } from './types'

export const APP_TYPES = ['I Only need login', 'I need login']

export const KYC_PROPERTIES = [
  {
    label: 'FullName',
    value: 'NAME_PARENT_FIELD',
    propertyRef: [
      {
        value: 'PASSPORT_NAME',
        label: 'Passport FullName',
      },
      {
        value: 'IDCARD_NAME',
        label: 'ID Card FullName',
      },
      {
        value: 'RESIDENCE_PERMIT_NAME',
        label: 'Residence Permit FullName',
      },
      {
        value: 'DRIVER_LICENSE_NAME',
        label: 'Driver License FullName',
      },
      {
        value: 'ADDRESS_NAME',
        label: 'Address Name',
      },
    ],
  },
  {
    label: 'Date of Birth',
    value: 'DOB_PARENT_FIELD',
    propertyRef: [
      {
        value: 'PASSPORT_DOB',
        label: 'Passport Date of Birth',
      },
      {
        value: 'IDCARD_DOB',
        label: 'ID Card Date of Birth',
      },
      {
        value: 'RESIDENCE_PERMIT_DOB',
        label: 'Residence Permit Date of Birth',
      },
      {
        value: 'DRIVER_LICENSE_DOB',
        label: 'Driver License Date of Birth',
      },
    ],
  },
  {
    label: 'Gender',
    value: 'SEX_PARENT_FIELD',
    propertyRef: [
      {
        value: 'PASSPORT_SEX',
        label: 'Passport Gender',
      },
      {
        value: 'IDCARD_SEX',
        label: 'ID Card Gender',
      },
      {
        value: 'RESIDENCE_PERMIT_SEX',
        label: 'Residence Permit Gender',
      },
      {
        value: 'DRIVER_LICENSE_SEX',
        label: 'Driver License Gender',
      },
    ],
  },
  {
    label: 'Image',
    value: 'FACE_IMAGE_PARENT_FIELD',
    propertyRef: [
      {
        value: 'DRIVER_LICENSE_FACE_IMAGE',
        label: 'Driver License Image',
      },
      {
        value: 'IDCARD_FACE_IMAGE',
        label: 'ID Card Image',
      },
      {
        value: 'PASSPORT_FACE_IMAGE',
        label: 'Passport Image',
      },
      {
        value: 'RESIDENCE_PERMIT_FACE_IMAGE',
        label: 'Residence Permit Image',
      },
    ],
  },
  {
    label: 'FullName English',
    value: 'NAME_EN_PARENT_FIELD',
    propertyRef: [
      {
        value: 'ADDRESS_NAME_EN',
        label: 'Address Name English',
      },
      {
        value: 'RESIDENCE_PERMIT_NAME_EN',
        label: 'Residence Permit FullName English',
      },
      {
        value: 'PASSPORT_NAME_EN',
        label: 'Passport FullName English',
      },
      {
        value: 'IDCARD_NAME_EN',
        label: 'ID Card FullName English',
      },
      {
        value: 'DRIVER_LICENSE_NAME_EN',
        label: 'Driver License FullName English',
      },
    ],
  },
  {
    label: 'Others',
    propertyRef: [
      {
        value: 'IDCARD_EXPIRY_DATE',
        label: 'ID Card Expiry Date',
      },
      {
        value: 'IDCARD_ISSUE_DATE',
        label: 'ID Card Issue Date',
      },
      {
        value: 'IDCARD_DOCUMENT_NUMBER',
        label: 'ID Card Document Number',
      },
      {
        value: 'IDCARD_COUNTRY',
        label: 'ID Card Country',
      },
      {
        value: 'IDCARD_DOCUMENT_IMAGE',
        label: 'ID Card Document Image',
      },
      {
        value: 'IDCARD_ADDITIONAL_IMAGE',
        label: 'ID Card Additional Image',
      },
      {
        value: 'IDCARD_AML_CHECK',
        label: 'ID Card AML Check',
      },
      {
        value: 'IDCARD_AML_DETAIL',
        label: 'ID Card AML Detail',
      },
      {
        value: 'IDCARD_NATIONALITY',
        label: 'ID Card Nationality',
      },
      {
        value: 'IDCARD_PLACE_OF_BIRTH',
        label: 'ID Card Place of Birth',
      },
      {
        value: 'IDCARD_NOTE',
        label: 'ID Card Note',
      },

      {
        value: 'PASSPORT_EXPIRY_DATE',
        label: 'Passport Expiry Date',
      },
      {
        value: 'PASSPORT_ISSUE_DATE',
        label: 'Passport Issue Date',
      },
      {
        value: 'PASSPORT_DOCUMENT_NUMBER',
        label: 'Passport Document Number',
      },
      {
        value: 'PASSPORT_COUNTRY',
        label: 'Passport Country',
      },
      {
        value: 'PASSPORT_DOCUMENT_IMAGE',
        label: 'Passport Document Image',
      },
      {
        value: 'PASSPORT_ADDITIONAL_IMAGE',
        label: 'Passport Additional Image',
      },
      {
        value: 'PASSPORT_AML_CHECK',
        label: 'Passport AML Check',
      },
      {
        value: 'PASSPORT_AML_DETAIL',
        label: 'Passport AML Detail',
      },
      {
        value: 'PASSPORT_NATIONALITY',
        label: 'Passport Nationality',
      },
      {
        value: 'PASSPORT_PLACE_OF_BIRTH',
        label: 'Passport Place of Birth',
      },
      {
        value: 'PASSPORT_NOTE',
        label: 'Passport Note',
      },

      {
        value: 'RESIDENCE_PERMIT_EXPIRY_DATE',
        label: 'Residence Permit Expiry Date',
      },
      {
        value: 'RESIDENCE_PERMIT_ISSUE_DATE',
        label: 'Residence Permit Issue Date',
      },
      {
        value: 'RESIDENCE_PERMIT_DOCUMENT_NUMBER',
        label: 'Residence Permit Document Number',
      },
      {
        value: 'RESIDENCE_PERMIT_COUNTRY',
        label: 'Residence Permit Country',
      },
      {
        value: 'RESIDENCE_PERMIT_DOCUMENT_IMAGE',
        label: 'Residence Permit Document Image',
      },
      {
        value: 'RESIDENCE_PERMIT_ADDITIONAL_IMAGE',
        label: 'Residence Permit Additional Image',
      },
      {
        value: 'RESIDENCE_PERMIT_AML_CHECK',
        label: 'Residence Permit AML Check',
      },
      {
        value: 'RESIDENCE_PERMIT_AML_DETAIL',
        label: 'Residence Permit AML Detail',
      },
      {
        value: 'RESIDENCE_PERMIT_NATIONALITY',
        label: 'Residence Permit',
      },
      {
        value: 'RESIDENCE_PERMIT_PLACE_OF_BIRTH',
        label: 'Residence Permit Place of Birth',
      },
      {
        value: 'RESIDENCE_PERMIT_NOTE',
        label: 'Residence Permit Note',
      },

      {
        value: 'DRIVER_LICENSE_EXPIRY_DATE',
        label: 'Driver License Expiry Data',
      },
      {
        value: 'DRIVER_LICENSE_ISSUE_DATE',
        label: 'Driver License Issue Data',
      },
      {
        value: 'DRIVER_LICENSE_DOCUMENT_NUMBER',
        label: 'Driver License Document Number',
      },
      {
        value: 'DRIVER_LICENSE_COUNTRY',
        label: 'Driver License Country',
      },
      {
        value: 'DRIVER_LICENSE_DOCUMENT_IMAGE',
        label: 'Driver License Document Image',
      },
      {
        value: 'DRIVER_LICENSE_ADDITIONAL_IMAGE',
        label: 'Driver License Additional Image',
      },
      {
        value: 'DRIVER_LICENSE_AML_CHECK',
        label: 'Driver License AML Check',
      },
      {
        value: 'DRIVER_LICENSE_AML_DETAIL',
        label: 'Driver License AML Detail',
      },
      {
        value: 'DRIVER_LICENSE_NATIONALITY',
        label: 'Driver License National',
      },
      {
        value: 'DRIVER_LICENSE_PLACE_OF_BIRTH',
        label: 'Driver License Place of Birth',
      },
      {
        value: 'DRIVER_LICENSE_NOTE',
        label: 'Driver License Note',
      },
      {
        value: 'ADDRESS_ISSUE_DATE',
        label: 'Address Issue Date',
      },
      {
        value: 'ADDRESS_EXPIRY_DATE',
        label: 'Address Expiry Date',
      },
      {
        value: 'ADDRESS_STREET',
        label: 'Address Street',
      },
      {
        value: 'ADDRESS_STREET2',
        label: 'Address Street 2',
      },
      {
        value: 'ADDRESS_DIVISION',
        label: 'Address Division',
      },
      {
        value: 'ADDRESS_CITY',
        label: 'Address City',
      },
      {
        value: 'ADDRESS_STREET_EN',
        label: 'Address Street English',
      },
      {
        value: 'ADDRESS_STREET2_EN',
        label: 'Address Street 2 English',
      },
      {
        value: 'ADDRESS_DIVISION_EN',
        label: 'Address Division English',
      },
      {
        value: 'ADDRESS_CITY_EN',
        label: 'Address City English',
      },
      {
        value: 'ADDRESS_ZIP_CODE',
        label: 'Address Zip Code',
      },
      {
        value: 'ADDRESS_COUNTRY',
        label: 'Address Country',
      },
      {
        value: 'ADDRESS_DOCUMENT_IMAGE',
        label: 'Address Document Image',
      },
      {
        value: 'ADDRESS_NOTE',
        label: 'Address Note',
      },
      {
        value: 'EMAIL',
        label: 'Email',
      },
      {
        value: 'PHONE',
        label: 'Phone',
      },
    ],
  },
]

export const SELECTABLE_PACKAGE_TYPES = {
  BUNDLE: 'BUNDLE',
  PAY_AS_YOU_GO: 'CHARGE',
}

export const PACKAGES_TYPES = {
  ...SELECTABLE_PACKAGE_TYPES,
  RENEWAL: 'RENEWAL',
}

export enum PACKAGE_STEPS_NAMES {
  SELECT_DOMAIN = 'Select Domain',
  PACKAGE_TYPE = 'Package Type',
  REQUIRED_FIELDS = 'Required Fields',
  OPTIONAL_FIELDS = 'Optional Fields',
  QUANTITY = 'Quantity',
  CONFIRM_PAYMENT = 'Confirm Payment',
}

export const ADD_PACKAGE_STEPS: PackageStep[] = [
  {
    label: PACKAGE_STEPS_NAMES.SELECT_DOMAIN,
    component: DomainSelect,
    included: [PACKAGES_TYPES.BUNDLE, PACKAGES_TYPES.PAY_AS_YOU_GO],
  },
  {
    label: PACKAGE_STEPS_NAMES.PACKAGE_TYPE,
    component: PackageType,
    included: [PACKAGES_TYPES.BUNDLE, PACKAGES_TYPES.PAY_AS_YOU_GO],
  },
  {
    label: PACKAGE_STEPS_NAMES.REQUIRED_FIELDS,
    component: RequiredFields,
    included: [PACKAGES_TYPES.BUNDLE],
    props: {
      required: true,
    },
  },
  {
    label: PACKAGE_STEPS_NAMES.OPTIONAL_FIELDS,
    component: RequiredFields,
    included: [PACKAGES_TYPES.BUNDLE],
    props: {
      required: false,
    },
  },
  {
    label: PACKAGE_STEPS_NAMES.QUANTITY,
    component: QuantitySelection,
    included: [
      PACKAGES_TYPES.BUNDLE,
      PACKAGES_TYPES.PAY_AS_YOU_GO,
      PACKAGES_TYPES.RENEWAL,
    ],
  },
  {
    label: PACKAGE_STEPS_NAMES.CONFIRM_PAYMENT,
    component: ConfirmPayment,
    included: [
      PACKAGES_TYPES.BUNDLE,
      PACKAGES_TYPES.PAY_AS_YOU_GO,
      PACKAGES_TYPES.RENEWAL,
    ],
  },
]
export const sourceKeyColumns = [
  { id: 'domainName', label: 'Domain' },
  { id: 'sourceKey', label: 'sourceKey' },
  { id: 'plan', label: 'Plan' },
  { id: 'quantity', label: 'Quantity' },
  { id: 'chargeAmount', label: 'chargeAmount' },
  { id: 'action', label: 'Details', actionKey: 'uuid' },
  { id: 'action1', label: 'Renew', actionKey: 'uuid' },
]
