import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WebhookResponseData, ErrorResponse, WebhookState } from './types'

export const initialState: WebhookState = {
  webhook: null,
  webhookLoading: false,
  error: null,
  isWebhookModalDisplayed: false,
  lastWebhookId: 0,
}

const webhookSlice = createSlice({
  name: 'webhook',
  initialState,
  reducers: {
    getWebhook() {},
    setWebhook(state, action: PayloadAction<WebhookResponseData[] | null>) {
      state.webhook = action.payload
    },
    setWebhookLoading(state, action: PayloadAction<boolean>) {
      state.webhookLoading = action.payload
    },
    setWebhookError(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload
    },
    setWebhookDisplayModal(state, action: PayloadAction<boolean>) {
      state.isWebhookModalDisplayed = action.payload
    },
    setSelectedClientDomainUUID(state, action: PayloadAction<string>) {
      state.selectedClientDomainUUID = action.payload
    },
    setLastWebhookId(state, action: PayloadAction<number>) {
      state.lastWebhookId = action.payload
    },
  },
})

export const {
  actions: webhookActions,
  reducer: webhookReducer,
  name: sliceKey,
} = webhookSlice

export default webhookSlice.reducer
