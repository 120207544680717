import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { profileActions } from './slice'
import { profileSelectors } from './selectors'
import { ErrorText } from './styles'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import KycData from './components/kycData'
import { useloginSlice } from '../Login/slice'
import QrCodeModal from './components/qrCodeModal'
import PrimaryButton from 'app/components/button/primaryButton'

export function Profile() {
  useloginSlice()
  const error = useSelector(profileSelectors.error)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(profileActions.getKYC())
  }, [dispatch])

  return (
    <>
      <QrCodeModal />
      <PageHeader title="Profile" />
      {error?.message ? (
        <>
          <ErrorText>{error?.message}</ErrorText>
          {error.code === 404 ? (
            <PrimaryButton
              onClick={() => dispatch(profileActions.startKYCProcess())}
            >
              Kyc Your Data
            </PrimaryButton>
          ) : undefined}
        </>
      ) : (
        <KycData />
      )}
    </>
  )
}
