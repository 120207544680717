import { FC } from 'react'
import { styled } from '@mui/material'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ROW_CENTER } from 'styles/globalStyles'
import { webhookActions } from 'app/containers/Webhook/slice'
import { webhookSelectors } from 'app/containers/Webhook/selector'
import BaseDropdownInput from 'app/components/input/dropdownInput'

interface Props {}

export const SelectApplication: FC<Props> = props => {
  const dispatch = useDispatch()
  const domains = useSelector(applicationsSelectors.domains)
  const selectedDomain = useSelector(webhookSelectors.selectedClientDomainUUID)

  const handleChange = (event: any) => {
    dispatch(webhookActions.setSelectedClientDomainUUID(event.target.value))
    dispatch(webhookActions.getWebhook())
  }

  return (
    <RowContainer>
      <p>Select Domain:</p>
      <SelectOrganizationDropdown
        options={domains?.map(({ uuid, domain }) => ({
          value: uuid,
          label: domain,
        }))}
        placeholder="Select Domain"
        label="Select Domain"
        onChange={handleChange}
        value={selectedDomain}
      />
    </RowContainer>
  )
}

const RowContainer = styled('div')`
  ${ROW_CENTER as any}
  gap: 10px;
  width: auto;
  & > *:first-of-type {
    white-space: nowrap;
    width: fit-content;
    font-weight: 700;
  }
`

const SelectOrganizationDropdown = styled(BaseDropdownInput)`
  width: 250px;
`
