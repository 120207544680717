import axiosInstance from 'api/axios'
import { ApiKeysEndpoints } from 'api/endpoints/apiKeys'
import { AxiosResponse } from 'axios'

export function* getApiKeysList() {
  const response: AxiosResponse = yield axiosInstance.get(
    ApiKeysEndpoints.apiKeysList,
  )
  return response
}

export function* createApiKeyReq(clientDomainUuid: {
  clientDomainUUID: string
}) {
  const response: AxiosResponse = yield axiosInstance.post(
    ApiKeysEndpoints.createApiKey,
    clientDomainUuid,
  )
  return response
}
