import { styled, css } from '@mui/material'
import {
  ROW_JUSTIFY_START_ALIGN_CENTER,
  UNSELECTABLE,
} from 'styles/globalStyles'
import SVG from 'react-inlinesvg'
export const Wrapper = styled('div')`
  position: fixed;
  width: 264px;
  height: 96vh;
  background: var(--mirage);
  border-radius: 16px;
  margin: 16px;
  padding: 32px 16px;
`
export const CorePassLogo = styled('img')`
  margin-left: 8px;
  margin-bottom: 56px;
`
export const ItemText = styled('div')`
  ${UNSELECTABLE}
  margin-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--causeway);
`
export const ItemIcon = styled(SVG)`
  margin-left: 15px;
`
const whitenTabItemComponents = `
  ${ItemText} {
    color: var(--white);
  }
  ${ItemIcon} {
    & path {
      stroke: var(--white);
    }
    & circle {
      stroke: var(--white);
    }
  }
`
export const TabItem = styled('div')<{
  selected?: boolean
  disableItem?: boolean
}>`
  ${props => {
    if (props.selected) {
      return css`
        background: var(--infinity) !important;
        ${whitenTabItemComponents}
      `
    }
  }}
  ${props => {
    if (props.disableItem) {
      return css`
        &:hover {
          cursor: default;
          color: grey;
        }
      `
    } else {
      return css`
        &:hover {
          ${whitenTabItemComponents}
        }
      `
    }
  }}
  ${ROW_JUSTIFY_START_ALIGN_CENTER}
  cursor: pointer;
  width: 232px;
  height: 48px;
  background: transparent;
  border-radius: 12px;
`

export const Devider = styled('div')`
  background-color: var(--pacific-line);
  width: 90%;
  height: 1px;
  margin: 40px 12px;
`
