import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { oauthSelectors } from '../selectors'
import { styled } from '@mui/material'
import { OAuthActions } from '../slice'
import { oAuthColumns } from '../constants'
import { OAuthActionComponent } from './oauthActionCopmonent'
import SortableTable from 'app/components/table/sortableTable'

export const OAuthtable = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(OAuthActions.getOAuths())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const oauths = useSelector(oauthSelectors.oauths)
  const isLoadingOauth = useSelector(oauthSelectors.isLoadingOauth)

  const speceficRowStyleBasedOnCellValue = {
    actionComponent: [OAuthActionComponent],
    cellKey: '',
    cellValue: '',
  }

  return (
    <>
      <SortableTable
        name="domain-table"
        data={oauths}
        columns={oAuthColumns}
        speceficRowStyleBasedOnCellValue={speceficRowStyleBasedOnCellValue}
        isLoading={isLoadingOauth}
        showNumberOfPages={false}
      />
    </>
  )
}
