import { useEffect } from 'react'
import {
  CorePassLogo,
  Devider,
  ItemIcon,
  ItemText,
  TabItem,
  Wrapper,
} from './styles'
import corepassLogoText from 'assets/svg/corepass_logo_text.svg'
import balanceIcon from 'assets/svg/balance.svg'
import invoicesIcon from 'assets/svg/invoices.svg'
import overviewIcon from 'assets/svg/overview.svg'
import paymentIcon from 'assets/svg/payment.svg'
import webhook from 'assets/svg/webhook.svg'
import role from 'assets/svg/roleManagment.svg'
import logoutIcon from 'assets/svg/logout.svg'
import history from 'router/history'
import { useLocation } from 'react-router-dom'
import { AppPages } from 'app/constants'
import { useDispatch, useSelector } from 'react-redux'
import { profileSelectors } from 'app/containers/Profile/selectors'
import { loginActions } from 'app/containers/Login/slice'

function SideBar() {
  const location = useLocation()
  const dispatch = useDispatch()
  const isUserUnregistered = useSelector(profileSelectors.isUserUnregistered)

  const handleLogOutClick = () => {
    dispatch(loginActions.logout())
    history.push(AppPages.Login)
  }
  useEffect(() => {}, [])
  const tabs = [
    {
      name: 'Profile',
      img: overviewIcon,
      onclick: () => {
        history.push(AppPages.profile)
      },
    },
    {
      name: 'Applications',
      img: balanceIcon,
      onclick: () => {
        history.push(AppPages.Applications)
      },
    },
    {
      name: 'Packages',
      img: invoicesIcon,
      onclick: () => {
        history.push(AppPages.Packages)
      },
    },
    {
      name: 'ApiKeys',
      img: balanceIcon,
      onclick: () => {
        history.push(AppPages.ApiKeys)
      },
    },
    {
      name: 'OAuth',
      img: paymentIcon,
      onclick: () => {
        history.push(AppPages.OAuth)
      },
    },
    {
      name: 'Roles',
      img: role,
      onclick: () => {
        history.push(AppPages.Roles)
      },
    },
    {
      name: 'Webhook',
      img: webhook,
      onclick: () => {
        history.push(AppPages.Webhook)
      },
    },
  ]

  return (
    <Wrapper>
      <CorePassLogo src={corepassLogoText} />
      {tabs.map(item => {
        return (
          <TabItem
            key={item.name}
            onClick={!isUserUnregistered ? item.onclick : () => {}}
            selected={
              location.pathname.split('/')[1] ===
              item.name.split(' ')[0].toLocaleLowerCase()
            }
            disableItem={item.name !== 'Profile' ? isUserUnregistered : false}
          >
            <ItemIcon src={item.img}></ItemIcon>
            <ItemText>{item.name}</ItemText>
          </TabItem>
        )
      })}
      <Devider />
      <TabItem onClick={handleLogOutClick} disableItem={false}>
        <ItemIcon src={logoutIcon}></ItemIcon>
        <ItemText>Logout</ItemText>
      </TabItem>
    </Wrapper>
  )
}

export default SideBar
