import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiKeysSelectors } from '../selectors'
import { ApiKeysActions } from '../slice'
import SortableTable from 'app/components/table/sortableTable'
import { sourceKeyColumns } from '../constants'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { ApplicationsActions } from 'app/containers/Applications/slice'

export const ApiKeysTable = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(ApiKeysActions.getApiKeys())
    dispatch(ApplicationsActions.getApplications())
  }, [dispatch])
  const isLoadingApiKeys = useSelector(apiKeysSelectors.isLoadingApiKeys)
  const isLoadingApplications = useSelector(
    applicationsSelectors.isLoadingApplications,
  )
  const apiKeysWithDomains = useSelector(apiKeysSelectors.apiKeysWithDomain)

  return (
    <>
      <SortableTable
        name="sourcekey-table"
        data={apiKeysWithDomains}
        columns={sourceKeyColumns}
        isLoading={
          (isLoadingApiKeys || isLoadingApplications) &&
          !(apiKeysWithDomains?.length > 0)
        }
        showNumberOfPages={false}
      />
    </>
  )
}
