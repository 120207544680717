import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
:root {
  --mirage:#131722;
  --white:#ffffff;
  --lynch:#5D738F;
  --link-water:#DBE5F4;
  --denim:#1066DF;
  --infinity:#222531;
  --causeway:#6D7A8C;
  --pacific-line: #2e3344;
  --black-knight: #020c16;
  --royal-blue:#4085E5;
  --greek-sea: #70a3ec;
  --black:#000000;
  --spring-dew: #d7efe1;
  --red-panda: #cc4a1b;
  --lake-green: #2e8e58;
  --pink-sangria: #f5dbd1;
  --wash-me: #fafbfc;
  --blue-gray:#F5F7FB;
  --turquoise: #017E84;
  --Light-gray:#f9f9f9;

  --error-50: #fff4f4;
  --error-100: #fac8c8;
  --error-200: #f17e7d;
  --error-300: #f16b6a;
  --error-400: #f15857;
  --error-500: #f13e3d;
  --error-600: #de2d2c;
  --error-700: #c62221;
  --error-800: #b0100f;
}
.darkTheme {

}

body,
html {
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  font-family:"Inter",Arial, Helvetica, sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;


  //remove the arrow from the input number
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
}
}
*{
  box-sizing:border-box;
}
`
// COLUMN

export const ROW = `
  display: flex;
  flex-direction: row;
`
export const COLUMN = `
  display: flex;
  flex-direction: column;
`
const CENTER = `
  align-items: center;
  justify-content: center;
`
export const COLUMN_CENTER = `
  ${CENTER}
  ${COLUMN}
`
export const ROW_CENTER = `
  ${CENTER}
  ${ROW}
`
export const COLUMN_JUSTIFY_END_ALIGN_CENTER = `
  ${COLUMN}
  justify-content: flex-end;
  align-items: center;
`
export const COLUMN_JUSTIFY_START_ALIGN_CENTER = `
  ${COLUMN}
  justify-content: flex-start;
  align-items: center;
`
export const COLUMN_ALIGN_END_JUSTIFY_CENTER = `
  ${COLUMN}
  justify-content: center;
  align-items: flex-end;
`
export const COLUMN_ALIGN_START_JUSTIFY_CENTER = `
  ${COLUMN}
  justify-content: center;
  align-items: flex-start;
`
export const COLUMN_ALIGN_START_JUSTIFY_START = `
  ${COLUMN}
  justify-content: flex-start;
  align-items: flex-start;
`
export const COLUMN_ALIGN_END_JUSTIFY_END = `
  ${COLUMN}
  justify-content: flex-end;
  align-items: flex-end;
`
export const COLUMN_ALIGN_START_JUSTIFY_END = `
  ${COLUMN}
  justify-content: flex-end;
  align-items: flex-start;
`
export const COLUMN_ALIGN_END_JUSTIFY_START = `
  ${COLUMN}
  justify-content: flex-start;
  align-items: flex-end;
`
export const COLUMN_ALIGN_END_SPACE_B = `
  ${COLUMN}
  justify-content:space-between;
  align-items: flex-end;
`
export const COLUMN_ALIGN_START_SPACE_B = `
  ${COLUMN}
  justify-content:space-between;
  align-items: flex-start;
`
export const COLUMN_ALIGN_CENTER_SPACE_B = `
  ${COLUMN}
  justify-content:space-between;
  align-items: center;
`

// ROWS

export const ROW_JUSTIFY_END_ALIGN_CENTER = `
  ${ROW}
  justify-content: flex-end;
  align-items: center;
`
export const ROW_JUSTIFY_START_ALIGN_CENTER = `
  ${ROW}
  justify-content: flex-start;
  align-items: center;
`
export const ROW_ALIGN_END_JUSTIFY_CENTER = `
  ${ROW}
  justify-content: center;
  align-items: flex-end;
`
export const ROW_ALIGN_START_JUSTIFY_CENTER = `
  ${ROW}
  justify-content: center;
  align-items: flex-start;
`
export const ROW_ALIGN_START_JUSTIFY_START = `
  ${ROW}
  justify-content: flex-start;
  align-items: flex-start;
`
export const ROW_ALIGN_END_JUSTIFY_END = `
  ${ROW}
  justify-content: flex-end;
  align-items: flex-end;
`
export const ROW_ALIGN_START_JUSTIFY_END = `
  ${ROW}
  justify-content: flex-end;
  align-items: flex-end;
`
export const ROW_ALIGN_END_JUSTIFY_START = `
  ${ROW}
  justify-content: flex-end;
  align-items: flex-end;
`
export const ROW_ALIGN_END_SPACE_B = `
  ${ROW}
  justify-content:space-between;
  align-items: flex-end;
`
export const ROW_ALIGN_START_SPACE_B = `
  ${ROW}
  justify-content:space-between;
  align-items: flex-start;
`
export const ROW_ALIGN_CENTER_SPACE_B = `
  ${ROW}
  justify-content:space-between;
  align-items: center;
`
export const FULL_WIDTH = `
  width: 100%;
`
export const FULL_HEIGHT = `
  height: 100%;
`
export const UNSELECTABLE = `
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`
export const BUTTON = `
  ${UNSELECTABLE}
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
`

export const BORDER_BLACK_WHITE_4 = `
  position: relative;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 1.3px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.25)
    );
    -webkit-mask:
      linear-gradient(var(--White) 0 0) content-box,
      linear-gradient(var(--White) 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

export const BORDER_BLACK_WHITE_8 = `
  position: relative;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 1.3px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.25)
    );
    -webkit-mask:
      linear-gradient(var(--White) 0 0) content-box,
      linear-gradient(var(--White) 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

export const BORDER_BLACK_WHITE_12 = `
  position: relative;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1.3px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.25)
    );
    -webkit-mask:
      linear-gradient(var(--White) 0 0) content-box,
      linear-gradient(var(--White) 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

export const BORDER_BLACK_WHITE_16 = `
  position: relative;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.3px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.25)
    );
    -webkit-mask:
      linear-gradient(var(--White) 0 0) content-box,
      linear-gradient(var(--White) 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

export const HIDE_SCROLLBAR_BUT_ALLOW_SCROLLING = `
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`

export default GlobalStyle
