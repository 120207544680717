import { put, takeLatest } from 'redux-saga/effects'
import { ApplicationsActions } from './slice'
import {
  checkDomainStatusReq,
  createNewApplicationRequest,
  getApplicationsList,
} from './providers'
import { customToast } from 'app/components/toast/CustomToast'
import { txErrorMessage } from 'utils/helpers'
import { AxiosResponse } from 'axios'

export function* getApplications() {
  yield put(ApplicationsActions.setIsLoadingApplications(true))
  try {
    const response: AxiosResponse = yield getApplicationsList()
    yield put(
      ApplicationsActions.setApplications(response?.data?.data?.clientDomains),
    )
  } catch (error) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  } finally {
    yield put(ApplicationsActions.setIsLoadingApplications(false))
  }
}

export function* createNewApplication(
  action: ReturnType<typeof ApplicationsActions.createNewApplication>,
) {
  try {
    const response: AxiosResponse = yield createNewApplicationRequest(action)
    yield put(ApplicationsActions.getApplications())
    yield put(ApplicationsActions.setDisplayModal(false))
    yield put(
      ApplicationsActions.setNewAppValues({
        name: '',
        domain: '',
        bundleId: '',
        framework: '',
      }),
    )
    customToast.success(response?.data?.message || 'Process succeded')
  } catch (error) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* checkDomainStatus(
  action: ReturnType<typeof ApplicationsActions.checkDomainStatus>,
) {
  try {
    const domainUuid = action.payload
    const response: AxiosResponse = yield checkDomainStatusReq(domainUuid)
    customToast.success(response?.data?.message || 'Process succeded')
    yield put(ApplicationsActions.getApplications())
  } catch (error: any) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* applicationSaga() {
  yield takeLatest(ApplicationsActions.getApplications.type, getApplications)
  yield takeLatest(
    ApplicationsActions.createNewApplication.type,
    createNewApplication,
  )
  yield takeLatest(
    ApplicationsActions.checkDomainStatus.type,
    checkDomainStatus,
  )
}
