import { Card, CardTitle, InfoAndValueWrapper, Wrapper } from './styles'
import { profileSelectors } from '../../selectors'
import { useSelector } from 'react-redux'
import { kycStatus } from '../../types'
function KycData() {
  const profile = useSelector(profileSelectors.kyc)

  return (
    <Wrapper>
      {profile &&
        Object?.keys(profile)?.map?.(item => {
          return (
            profile?.[item as keyof kycStatus] && (
              <Card key={item}>
                <CardTitle>{item}:</CardTitle>
                <InfoAndValueWrapper>
                  {profile[item as keyof kycStatus]}
                </InfoAndValueWrapper>
              </Card>
            )
          )
        })}
    </Wrapper>
  )
}

export default KycData
