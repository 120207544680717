import { all, put, select, takeLatest } from 'redux-saga/effects'
import { createOAuthReq, getOAuthsList, updateOAuthReq } from './providers'
import { customToast } from 'app/components/toast/CustomToast'
import { OAuthActions } from './slice'
import { oauthDomains } from './selectors'
import { OAUTH_VALUES } from './types'
import { txErrorMessage } from 'utils/helpers'
import { AxiosResponse } from 'axios'

export function* getOAuths() {
  yield put(OAuthActions.setIsLoadingOauth(true))
  try {
    const response: AxiosResponse = yield getOAuthsList()
    yield put(OAuthActions.setOAuths(response?.data?.data?.oauth ?? []))
  } catch (error) {
  } finally {
    yield put(OAuthActions.setIsLoadingOauth(false))
  }
}
export function* createUpdateOAuth(
  action: ReturnType<typeof OAuthActions.createUpdateOAuth>,
) {
  const crudSelectedItem: OAUTH_VALUES = yield select(
    oauthDomains.crudSelectedItem,
  )
  const crudAction = crudSelectedItem
    ? updateOAuthReq(action.payload)
    : createOAuthReq(action.payload)

  try {
    const response: AxiosResponse = yield crudAction
    const toCall = [
      put(OAuthActions.setDisplayModal(false)),
      put(OAuthActions.getOAuths()),
    ]
    yield all(toCall)
    customToast.success(response?.data?.message || 'Process succeded')
  } catch (error) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* oauthSaga() {
  yield takeLatest(OAuthActions.getOAuths.type, getOAuths)
  yield takeLatest(OAuthActions.createUpdateOAuth.type, createUpdateOAuth)
}
