import { baseURL } from 'app/constants'
import axios from 'axios'

export function getTokenAndOrganizationFromStorage() {
  const organizationUUID = localStorage['Organization-UUID'] || ''
  const token = localStorage.getItem('AUTH_ACCESS_TOKEN')
  return [token, organizationUUID]
}

const axiosInstance = axios.create({
  baseURL,
})

axiosInstance.interceptors.request.use(
  config => {
    const [token, organizationUUID] = getTokenAndOrganizationFromStorage()

    // Initialize config.headers as an object if it is undefined
    config.headers = config.headers || {}

    if (token) {
      config.headers['Authorization'] = `${token}`
    }

    // Initialize config.params as an object if it is undefined
    config.params = config.params || {}

    if (organizationUUID && token) {
      config.params['organizationUUID'] = organizationUUID
    }
    return config
  },
  error => {
    return Promise.reject({ error })
  },
)

export const axiosInstanceNotLoggedIn = axios.create({
  baseURL,
})
export default axiosInstance
