import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { packagesSelectors } from 'app/containers/Package/selectors'
import { PackagesActions } from 'app/containers/Package/slice'
import { PackagesState } from 'app/containers/Package/types'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
} from 'styles/globalStyles'
import { CRadioGroup } from 'app/components/radio-group'
interface Props {}
export const DomainSelect: FC<Props> = props => {
  const dispatch = useDispatch()
  const domains = useSelector(applicationsSelectors.domains)
  const { clientDomainUuid } = useSelector(packagesSelectors.newSourceKey)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      PackagesActions.setNewPackageValues({
        [event.target.name as keyof PackagesState['newSourceKey']]:
          event.target.value,
      }),
    )
  }

  return (
    <DeatilsContainer>
      <RowContainer>
        <Label>For which applications you want to use this package:</Label>
        <CRadioGroup
          name="clientDomainUuid"
          value={clientDomainUuid}
          handleChange={handleChange}
          options={domains?.map?.(({ uuid, domain }) => ({
            label: domain,
            value: uuid,
          }))}
        />
      </RowContainer>
    </DeatilsContainer>
  )
}

const DeatilsContainer = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
`

const RowContainer = styled('div')`
  ${ROW_CENTER}
  gap: 20px;
  & > *:first-of-type {
    width: 150px;
    font-weight: 700;
  }
`

const Label = styled('div')``
