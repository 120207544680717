import axiosInstance from 'api/axios'
import { ProfilesEndpoints } from 'api/endpoints/profile'
import { AxiosResponse } from 'axios'

export function* getKYCStatusReq() {
  const response: AxiosResponse = yield axiosInstance.get(
    ProfilesEndpoints.getKYCStatus,
  )
  return response
}

export function* startKYCProcessReq() {
  const response: AxiosResponse = yield axiosInstance.get(
    ProfilesEndpoints.startKYCProcess,
  )
  return response
}

export function* getOrganizationsReq() {
  const response: AxiosResponse = yield axiosInstance.get(
    ProfilesEndpoints.getOrganizations,
  )
  return response
}
