import { FC } from 'react'
import { styled, Box } from '@mui/material'

import { PurchasedPackages } from 'app/containers/Package/types'
import { useSelector } from 'react-redux'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { shortDateFormat } from 'utils/date'
import { packagesSelectors } from 'app/containers/Package/selectors'
import { Item, ItemConatiner } from './ItemWidget'
import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_JUSTIFY_START_ALIGN_CENTER,
} from 'styles/globalStyles'

export const DetailsModalContent: FC = () => {
  const {
    uuid,
    sourceKey,
    clientDomainUUID,
    quantity,
    fields,
    createdAt,
    updatedAt,
    plan,
    chargeAmount,
    storeInConnectorDatabase,
    optionalFields,
    webhookSecretKey,
  } =
    useSelector(packagesSelectors.detailsModalSelectedItem) ||
    ({} as PurchasedPackages)
  const domainName = useSelector(
    applicationsSelectors.domainNameBaseOnClientDomainUUID(clientDomainUUID),
  )

  return (
    <Container>
      <TwoItemsContainer>
        <Item label="Client Domain:" value={domainName} />
        <Item label="Client Domain UUID:" value={clientDomainUUID} />
      </TwoItemsContainer>
      <TwoItemsContainer>
        <Item label="Quantity:" value={quantity} />
        <Item label="Charge Amount:" value={chargeAmount} />
      </TwoItemsContainer>
      <TwoItemsContainer>
        <Item label="Created At:" value={shortDateFormat(createdAt)} />
        <Item label="Updated At:" value={shortDateFormat(updatedAt)} />
      </TwoItemsContainer>
      <TwoItemsContainer>
        <Item label="Plan:" value={plan} />
        <Item label="UUID:" value={uuid} />
      </TwoItemsContainer>
      <TwoItemsContainer>
        <Item
          label="Store In Database:"
          value={storeInConnectorDatabase ? 'Yes' : 'No'}
        />
        <ItemConatiner />
      </TwoItemsContainer>
      <Item label="Webhook Key:" value={webhookSecretKey} />
      <Item label="Source Key:" value={sourceKey} />

      {fields ? <Item label="Fields:" value={fields?.join(', ')} /> : <></>}
      {optionalFields ? (
        <Item label="Optional Fields:" value={optionalFields?.join(', ')} />
      ) : (
        <></>
      )}
    </Container>
  )
}

const Container = styled(Box)`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  gap: 32px;
  width: 100%;
`

const TwoItemsContainer = styled(Box)`
  ${ROW_JUSTIFY_START_ALIGN_CENTER}
  gap: 32px;
  width: 100%;
`
