import React, { FC, useEffect } from 'react'
import { applicationsSelectors } from 'app/containers/Applications/selectors'
import { ApplicationsActions } from 'app/containers/Applications/slice'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
} from 'styles/globalStyles'
import { ApiKeysActions } from '../slice'
import { apiKeysSelectors } from '../selectors'
import { CRadioGroup } from 'app/components/radio-group'
interface Props {}
export const DomainSelect: FC<Props> = props => {
  const dispatch = useDispatch()
  const domains = useSelector(applicationsSelectors.domains)
  const ClientDomainUuid = useSelector(apiKeysSelectors.ClientDomainUuid)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(ApiKeysActions.setClientDomainUuid(event.target.value as string))
  }

  useEffect(() => {
    dispatch(ApplicationsActions.getApplications())
  }, [dispatch])

  return (
    <DeatilsContainer>
      <RowContainer>
        <TitleContainer>
          For which applications you want to use this package:
        </TitleContainer>
        <CRadioGroup
          name="clientDomainUuid"
          options={domains.map(({ uuid, domain }) => ({
            label: domain,
            value: uuid,
          }))}
          value={ClientDomainUuid}
          handleChange={handleChange}
        />
      </RowContainer>
    </DeatilsContainer>
  )
}

const TitleContainer = styled('div')``
const DeatilsContainer = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
`

const RowContainer = styled('div')`
  ${ROW_CENTER}
  gap: 20px;
  & > *:first-of-type {
    width: 150px;
    font-weight: 700;
  }
`
